import { FromSchema } from "json-schema-to-ts";
import { SqlValue } from "sql.js";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName, RowData } from "../../data/conflict-free-db/core";
import {
  DeleteRowsOp,
  UpsertTableDataOp,
} from "../../data/conflict-free-db/operations";
import { shortId } from "../../data/uuid";
import { DbObject } from "./db-objects";
import { Migration } from "./migration";
import { makeValidator, nativeToSql, rowToNative } from "./schema";

export const dbLinksSchema = {
  title: "DbLinks",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    objectId: {
      type: "string",
    },
    url: {
      type: "string",
    },
    youTubeId: {
      type: "string",
    },
  },
  additionalProperties: false,
  required: [defaultIdName],
} as const;

export type DbLink = FromSchema<typeof dbLinksSchema>;

const dbLinkRowToNative = rowToNative(dbLinksSchema);
const isDbLink = makeValidator<DbLink>(dbLinksSchema);

export interface LinkListItem {
  id: string;
  url: string;
  youTubeId?: string;
  objectId: string;
  objectTitle: string;
}

export class DbLinks {
  constructor(
    private db: AppSqlApi,
    readonly tableName = "links",
  ) {}
  async initialize(migration: Migration) {
    const { tableName, db } = this;
    await migration.upgrade(tableName, 9, () => {
      db.execPrivate(`
        DROP TABLE IF EXISTS ${tableName};
        CREATE TABLE IF NOT EXISTS ${tableName}(
          id TEXT PRIMARY KEY,
          objectId TEXT,
          url TEXT,
          youTubeId TEXT
        );
        CREATE INDEX IF NOT EXISTS ${tableName}_objectId ON ${tableName}(objectId);
        CREATE INDEX IF NOT EXISTS ${tableName}_url ON ${tableName}(url);
        CREATE INDEX IF NOT EXISTS ${tableName}_youTubeId ON ${tableName}(youTubeId);
      `);
    });
  }
  add(link: DbLink) {
    this.upsert(link);
  }
  delete(id: string) {
    const op = new DeleteRowsOp(this.tableName, [id]);
    this.db.applyLocalOp(op);
  }
  getLinks(objectId: string) {
    const [results] = this.db.queryShared(
      `SELECT id, url FROM ${this.tableName} WHERE objectId = ?`,
      [objectId],
    );
    if (!results) {
      return [];
    }
    const items: DbLink[] = [];
    const cols = results.columns;
    for (const row of results.values) {
      const dbLink = this.rowToLink(cols, row);
      if (!dbLink) {
        continue;
      }
      items.push(dbLink);
    }
    return items;
  }
  updateLinks(objectId: string, urls: string[]) {
    const newUrls = new Set(urls);
    const current = this.getLinks(objectId);
    const oldUrls = new Set(
      current.map((x) => x.url).filter((x): x is string => !!x),
    );
    const toDelete = [...oldUrls].filter((x) => !newUrls.has(x));
    const deleteIds = current
      .filter((x) => x.url && toDelete.includes(x.url))
      .map((x) => x.id);
    const deleteOp = new DeleteRowsOp(this.tableName, deleteIds);
    this.db.applyPrivateOp(deleteOp);
    const toAdd = [...newUrls].filter((x) => !oldUrls.has(x));
    const links = toAdd.map((x) => this.makeLink(objectId, x));
    links.forEach((link) => this.upsert(link));
  }
  private upsert(link: DbLink) {
    const cols = Object.keys(dbLinksSchema.properties);
    const record = Object.fromEntries(Object.entries(link));
    const dataCols = cols.filter((x) => x !== defaultIdName);
    const rowData: RowData = {};
    for (const column of dataCols) {
      const nativeVal = record[column];
      if (nativeVal === undefined) {
        continue;
      }
      const sqlVal = nativeToSql(nativeVal);
      rowData[column] = sqlVal;
    }
    const op = new UpsertTableDataOp(this.tableName, {
      [link.id]: rowData,
    });
    this.db.applyPrivateOp(op);
  }
  private rowToLink(columns: string[], values: SqlValue[]): DbLink | undefined {
    const object = dbLinkRowToNative(columns, values);
    if (isDbLink(object)) {
      return object;
    } else {
      return;
    }
  }
  private makeLink(objectId: string, url: string): DbLink {
    const youTubeId = parseYouTubeUrl(url)?.id;
    return {
      id: shortId(),
      objectId,
      url,
      youTubeId,
    };
  }
}

const urlRegex = /https?:\/\/(?:[^\s]+)/gi;
export function extractUrlsFromObject(obj: DbObject): string[] {
  const text = (obj.title ?? "") + " " + (obj.text ?? "");
  return extractUrls(text);
}

export function extractUrls(text: string): string[] {
  const matches = [...text.matchAll(urlRegex)].flatMap((x) => x);
  return matches;
}

export interface YouTubeUrl {
  url: string;
  id: string;
}

const patterns = [
  /^https:\/\/www\.youtube\.com\/watch\?v=(?<id>[^&?]+)/i,
  /^https:\/\/youtu\.be\/(?<id>[^?/]+)/i,
  /^https:\/\/music\.youtube\.com\/watch\?v=(?<id>[^&?]+)/i,
];
export function parseYouTubeUrl(url: string): YouTubeUrl | undefined {
  for (const pattern of patterns) {
    const m = pattern.exec(url);
    if (m?.groups) {
      return { id: m.groups.id, url };
    }
  }
}
