import { GoogleAuthDb } from "../app-db";
import { defaultLogger, Logger } from "../logging";

export interface GoogleCredentials {
  token: string;
  expires: Date;
  hint?: string;
}

export interface GoogleAuthStore {
  get(): Promise<GoogleCredentials | undefined>;
  put(creds: GoogleCredentials): Promise<void>;
}

export class DbGoogleAuthStore implements GoogleAuthStore {
  private key = "google";
  private db: GoogleAuthDb;
  constructor(db: GoogleAuthDb) {
    this.db = db;
  }
  async get(): Promise<GoogleCredentials | undefined> {
    return this.db.googleAuth.get(this.key);
  }
  async put(creds: GoogleCredentials) {
    await this.db.googleAuth.put({ id: this.key, ...creds }, this.key);
  }
}

export function isExpiredAt(auth: GoogleCredentials, date: Date) {
  return auth.expires < date;
}

export function addSeconds(date: Date, seconds: number) {
  const result = new Date(date);
  result.setSeconds(date.getSeconds() + seconds);
  return result;
}

export type GoogleAuth = (
  now?: Date,
  hint?: string,
) => Promise<GoogleCredentials>;

async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const minVisibleDuration = 15 * 1000;
export function persist(
  auth: GoogleAuth,
  store: GoogleAuthStore,
  logger: Logger = defaultLogger,
  minVisible = minVisibleDuration,
): GoogleAuth {
  let visibleAt = new Date();
  window.addEventListener("visibilitychange", () => {
    if (window.document.visibilityState === "visible") {
      visibleAt = new Date();
    }
  });
  const visibleDuration = () => new Date().getTime() - visibleAt.getTime();
  return async function (now?: Date) {
    const savedCreds = await store.get();
    if (savedCreds && !isExpiredAt(savedCreds, now ?? new Date())) {
      return savedCreds;
    }
    const delta = visibleDuration() - minVisible;
    if (delta < 0) {
      await delay(-delta);
    }
    logger("Google credentials expired, getting new ones");
    const newCreds = await auth(now);
    await store.put(newCreds);
    return newCreds;
  };
}

function baseGoogleAuth(clientId: string, scope: string): GoogleAuth {
  return async function (_now, hint) {
    if (!navigator.onLine) {
      throw new Error("Cannot get auth token: disconnected");
    }
    return new Promise((resolve: (auth: GoogleCredentials) => void) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: scope,
        hint,
        prompt: "",
        select_account: false,
        callback: (resp: any) => {
          const auth = {
            token: resp.access_token as string,
            expires: addSeconds(new Date(), resp.expires_in),
          };
          resolve(auth);
        },
      });
      tokenClient.requestAccessToken();
    });
  };
}

function serialize(auth: GoogleAuth, logger: Logger): GoogleAuth {
  let queue = Promise.resolve<any>(undefined);
  return () => {
    const res = queue.then(() => auth());
    queue = res.catch((e) => {
      logger(`Error getting auth: ${e.message}`);
    });
    return res;
  };
}

async function getUserEmail(token: string): Promise<string> {
  const url = "https://www.googleapis.com/oauth2/v1/userinfo?alt=json";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const method = "get";
  const resp = await fetch(url, { headers, method });
  const json = await resp.json();
  return json.email as string;
}

function withHint(baseAuth: GoogleAuth, store: GoogleAuthStore): GoogleAuth {
  return async function (now) {
    const existing = await store.get();
    const creds = await baseAuth(now, existing?.hint);
    const hint = await getUserEmail(creds.token);
    return { ...creds, hint };
  };
}

function inputIsFocused() {
  const tag = window.document.activeElement?.tagName;
  return tag === "INPUT" || tag === "TEXTAREA";
}

async function waitUntilInputUnfocused() {
  if (!inputIsFocused()) {
    return;
  }
  await new Promise<void>((resolve) => {
    const cb = () => {
      if (!inputIsFocused()) {
        resolve();
        window.removeEventListener("focusin", cb);
        window.removeEventListener("focusout", cb);
      }
    };
    window.addEventListener("focusin", cb);
    window.addEventListener("focusout", cb);
  });
}

function blockOnInputFocus(auth: GoogleAuth): GoogleAuth {
  return async function (now, hint) {
    await waitUntilInputUnfocused();
    return auth(now, hint);
  };
}

export function googleAuth(
  clientId: string,
  scope: string,
  store: GoogleAuthStore,
  logger: Logger,
) {
  const auth = baseGoogleAuth(clientId, scope);
  const blocked = blockOnInputFocus(auth);
  const hinted = withHint(blocked, store);
  const persisted = persist(hinted, store, logger);
  const serialized = serialize(persisted, logger);
  return serialized;
}

export function googleAuthImmediate(
  clientId: string,
  scope: string,
  store: GoogleAuthStore,
  logger: Logger,
) {
  const auth = baseGoogleAuth(clientId, scope);
  const hinted = withHint(auth, store);
  const persisted = persist(hinted, store, logger, 0);
  const serialized = serialize(persisted, logger);
  return serialized;
}
