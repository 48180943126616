import { FromSchema, JSONSchema } from "json-schema-to-ts";
import { makeValidator } from "./schema";

const gptTextContentPart = {
  type: "object",
  properties: {
    type: {
      type: "string",
      enum: ["text"],
    },
    text: {
      type: "string",
    },
  },
  required: ["type", "text"],
} as const satisfies JSONSchema;
const gptImageContentPart = {
  type: "object",
  properties: {
    type: {
      type: "string",
      enum: ["image_url"],
    },
    image_url: {
      type: "object",
      properties: {
        url: {
          type: "string",
        },
        detail: {
          type: "string",
          enum: ["auto", "low", "high"],
        },
      },
      required: ["url"],
    },
  },
  required: ["type", "image_url"],
} as const satisfies JSONSchema;
const gptContentPart = {
  anyOf: [gptTextContentPart, gptImageContentPart],
} as const satisfies JSONSchema;

const gptContentArray = {
  type: "array",
  items: gptContentPart,
} as const satisfies JSONSchema;

const gptSystemMessage = {
  type: "object",
  properties: {
    content: {
      type: "string",
    },
    role: {
      type: "string",
      enum: ["system"],
    },
    name: {
      type: "string",
    },
  },
  required: ["content", "role"],
} as const satisfies JSONSchema;

const gptUserMessage = {
  type: "object",
  properties: {
    role: {
      type: "string",
      enum: ["user"],
    },
    content: {
      anyOf: [
        gptContentArray,
        {
          type: "string",
        },
      ],
    },
    name: {
      type: "string",
    },
  },
  required: ["content", "role"],
} as const satisfies JSONSchema;

const gptToolCall = {
  type: "object",
  properties: {
    id: {
      type: "string",
    },
    type: {
      type: "string",
      enum: ["function"],
    },
    function: {
      type: "object",
      properties: {
        name: {
          type: "string",
        },
        arguments: {
          type: "string",
        },
      },
      required: ["name", "arguments"],
    },
  },
  required: ["id", "type", "function"],
} as const satisfies JSONSchema;

const gptToolCallMessage = {
  type: "object",
  properties: {
    content: {
      anyOf: [{ type: "string" }, { type: "null" }],
    },
    role: {
      type: "string",
      enum: ["assistant"],
    },
    name: {
      type: "string",
    },
    tool_calls: {
      type: "array",
      items: gptToolCall,
    },
  },
  required: ["role"],
} as const satisfies JSONSchema;

const gptToolMessage = {
  type: "object",
  properties: {
    content: {
      type: "string",
    },
    role: {
      type: "string",
      enum: ["tool"],
    },
    tool_call_id: {
      type: "string",
    },
  },
  required: ["content", "role", "tool_call_id"],
} as const satisfies JSONSchema;

const gptMessage = {
  anyOf: [gptUserMessage, gptToolCallMessage, gptToolMessage],
} as const satisfies JSONSchema;

const versionedGptMessage = {
  type: "object",
  properties: {
    version: {
      type: "string",
      enum: ["v1"],
    },
    message: gptMessage,
  },
  required: ["version", "message"],
} as const satisfies JSONSchema;
export type GptMessage = FromSchema<typeof gptMessage>;
export const isGptMessage = makeValidator<GptMessage>(gptMessage);
export type VersionedGptMessage = FromSchema<typeof versionedGptMessage>;
export const isVersionedGptMessage =
  makeValidator<VersionedGptMessage>(versionedGptMessage);
export const getVersionedGptMessage = (x: string) => {
  try {
    const obj = JSON.parse(x);
    if (isVersionedGptMessage(obj)) {
      return obj;
    }
  } catch {
    return;
  }
};
