import { BindParams, QueryExecResult } from "sql.js";
import {
  Change,
  ConflictFreeDb,
  looksLikeWrite,
  PhysicalDb,
  VirtualDb,
} from "./core";

export class ConflictFreeDbV2 implements ConflictFreeDb {
  constructor(
    readonly virtualDb: VirtualDb,
    readonly physicalDb: PhysicalDb,
  ) {}
  apply(change: Change): boolean {
    const { operation, timestamp } = change;
    const { virtualDb, physicalDb } = this;
    return operation.applyAndReconcile(
      virtualDb.updateAt(timestamp),
      physicalDb,
    );
  }
  query(sql: string, params?: BindParams | undefined): QueryExecResult[] {
    if (looksLikeWrite(sql)) {
      throw new Error(
        "Sql query includes a mutation that is not allowed in conflict-free sql. Create a change object instead.",
      );
    }
    return this.physicalDb.exec(sql, params);
  }
}
