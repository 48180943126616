import { AuthManager } from "../auth";
import { GoogleAuth } from "./google-drive-auth";

declare const GOOGLE_APP_ID: string;
declare const GOOGLE_DEVELOPER_KEY: string;

function gapiLoad(name: string) {
  return new Promise((resolve) => gapi.load(name, resolve));
}

function buildPicker({
  appId,
  authToken,
  developerKey,
}: {
  appId: string;
  authToken: string;
  developerKey: string;
}) {
  const view = new google.picker.DocsView()
    .setIncludeFolders(true)
    .setMimeTypes("application/vnd.google-apps.folder")
    .setSelectFolderEnabled(true);
  const picker = new google.picker.PickerBuilder()
    .addView(view)
    .setOAuthToken(authToken)
    .setAppId(appId)
    .setDeveloperKey(developerKey)
    .build();
  return picker;
}

export interface GDriveFolder {
  id: string;
  name: string;
}

export type GDriveFolderPicker = () => Promise<GDriveFolder>;

export function googleFolderPicker(
  auth: GoogleAuth,
  appId: string,
  developerKey: string,
) {
  let initialized = false;
  async function initialize() {
    if (initialized) {
      return;
    }
    if (!gapi) {
      throw new Error("gapi not available");
    }
    await gapiLoad("picker");
    initialized = true;
  }
  return async function () {
    await initialize();
    const creds = await auth();
    const picker = buildPicker({ appId, authToken: creds.token, developerKey });
    picker.setVisible(true);
    return new Promise<GDriveFolder>((resolve, reject) => {
      picker.setCallback((result) => {
        const action = result[google.picker.Response.ACTION];
        if (action === google.picker.Action.PICKED) {
          const doc = result[google.picker.Response.DOCUMENTS][0];
          const id = doc[google.picker.Document.ID];
          const name = doc[google.picker.Document.NAME];
          resolve({ id, name });
        } else if (action === google.picker.Action.CANCEL) {
          reject("failed to pick");
        }
      });
    });
  };
}

export function initializePicker(auth: AuthManager) {
  return googleFolderPicker(
    auth.getGoogle(),
    GOOGLE_APP_ID,
    GOOGLE_DEVELOPER_KEY,
  );
}
