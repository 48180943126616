import { PeriodicBackgroundJob } from "./background-jobs";
import { gdriveCrawler } from "../google-drive/google-drive-crawler";
import { gdriveSearch } from "../google-drive/google-drive-api";
import { Logger, prefix } from "../logging";
import { GDriveCrawlerStore } from "../google-drive/google-drive-crawler-store";
import { GoogleAuth } from "../google-drive/google-drive-auth";
import { AppFileSystem } from "../app-file-system";

export const gdriveCrawlJob = (
  auth: GoogleAuth,
  fs: AppFileSystem,
  crawlerStore: GDriveCrawlerStore,
  logger: Logger,
) => {
  logger = prefix(logger, "[Crawl]");
  return new PeriodicBackgroundJob({
    name: "crawl",
    delayMs: 1000,
    job: async () => {
      if (!navigator.onLine) {
        return;
      }
      const getToken = async () => {
        const creds = await auth();
        return creds.token;
      };
      const search = gdriveSearch(getToken);
      const crawl = gdriveCrawler(
        fs.sources,
        fs.files,
        crawlerStore,
        search,
        logger,
      );
      await crawl();
    },
  });
};
