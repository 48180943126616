import { FromSchema } from "json-schema-to-ts";
import { AppSqlApi } from "../../data/app-sql-api";
import { defaultIdName } from "../../data/conflict-free-db/core";
import { DeleteRowsOp } from "../../data/conflict-free-db/operations";
import { createTableOp, makeValidator, rowToNative, upsert } from "./schema";

export const dbSearchTermsSchema = {
  title: "DbSearchTerms",
  type: "object",
  properties: {
    [defaultIdName]: {
      type: "string",
    },
    objectId: {
      type: "string",
    },
    term: {
      type: "string",
    },
  },
  additionalProperties: false,
  required: [defaultIdName],
} as const;
export type DbSearchTerm = FromSchema<typeof dbSearchTermsSchema>;
export const dbSearchTermRowToNative = rowToNative(dbSearchTermsSchema);
export const isDbSearchTerm = makeValidator<DbSearchTerm>(dbSearchTermsSchema);

export interface SearchTermListItem {
  id: string;
  term: string;
  objectId: string;
}

export class DbSearchTerms {
  constructor(
    private db: AppSqlApi,
    readonly tableName = "searchTerms",
  ) {}
  async initialize() {
    const createTable = createTableOp(this.tableName, dbSearchTermsSchema);
    this.db.applyLocalOp(createTable);
    this.db.queryShared(
      `CREATE INDEX IF NOT EXISTS ${this.tableName}_objectId ON ${this.tableName}(objectId)`,
    );
    this.db.queryShared(
      `CREATE INDEX IF NOT EXISTS ${this.tableName}_term ON ${this.tableName}(term)`,
    );
  }
  getSearchTermsForObjectId(objectId: string) {
    const [results] = this.db.queryShared(
      `SELECT id, objectId, term FROM ${this.tableName} WHERE objectId = ?`,
      [objectId],
    );
    if (!results) {
      return [];
    }
    const items: SearchTermListItem[] = [];
    for (const row of results.values) {
      const [id, objectId, term] = row as [string, string, string];
      items.push({
        id,
        objectId,
        term,
      });
    }
    return items;
  }
  addSearchTerm(objectId: string, term: string) {
    const id = this.makeId(objectId, term);
    const row = { id, objectId, term };
    const cols = Object.keys(dbSearchTermsSchema.properties);
    upsert(row, this.tableName, cols, this.db);
    return id;
  }
  removeSearchTerm(objectId: string, term: string) {
    const id = this.makeId(objectId, term);
    this.db.applyLocalOp(new DeleteRowsOp(this.tableName, [id]));
  }

  private makeId(objectId: string, term: string) {
    return `${objectId}:${term}`;
  }
}
