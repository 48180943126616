import React, { useMemo } from "react";
import { AttachmentListItem } from "../db-attachments";
import { FpApi } from "../fp-api";
import { useFpApi, useObjectNavigate } from "../notes-hooks";
import { GridEditor, GridEditorProps } from "./editor/GridEditor";

export function GalleryView({ id }: { id: string }) {
  const api = useFpApi();
  const navigate = useObjectNavigate();
  const props = useMemo(() => getProps(id, api, navigate), [id, api, navigate]);
  return <GridEditor {...props} />;
}

const pageSize = 100;
function getProps(
  id: string,
  api: FpApi,
  navigate: (id: string) => void,
): GridEditorProps<AttachmentListItem> {
  return {
    getImageId: (attachment) => attachment.fileId,
    getObjects: ({ input, page, queries }) =>
      queries
        .searchImages(input, pageSize, page * pageSize)
        .filter((x) => x.objectId !== id),
    getSelectedObjects: ({ input, queries }) =>
      queries.relatedImages(id, input).filter((x) => x.objectId !== id),
    getObjectId: (attachment) => attachment.attachmentId,
    getPrimaryText: (attachment) => attachment.objectTitle ?? attachment.title,
    toggle: (attachment) => {
      if (!attachment.objectId) {
        return;
      }
      if (api.hasTag(attachment.objectId, id)) {
        api.deleteTagWhere(attachment.objectId, id);
      } else {
        api.addTag(attachment.objectId, id);
      }
    },
    navigate: (attachment) =>
      attachment.objectId && navigate(attachment.objectId),
  };
}
