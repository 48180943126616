import { Folder } from "@mui/icons-material";
import { Box, Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import React, { FocusEvent, useCallback } from "react";
import { InlineEdit } from "../InlineEdit";
import { FpApi, ObjectDetails } from "../fp-api";
import { HideControl } from "./actions/HideControl";
import { StarControl } from "./actions/StarControl";

export function ObjectTitle({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const theme = useTheme();
  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (object.type !== "file") {
        return;
      }
      const match = /\.[^.]+$/.exec(object.title);
      if (!match) {
        return;
      }
      e.target.setSelectionRange(0, match.index);
    },
    [object.title, object.type],
  );
  return (
    <Box style={{ display: "flex", flexDirection: "row" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          flexGrow: 1,
        }}
      >
        {object.type === "file" && (
          <Grid item sx={{ marginTop: 1, marginRight: 1 }}>
            <Tooltip
              sx={{ flexGrow: 1 }}
              title={"Linked to a file in your folder"}
            >
              <IconButton
                href={`https://drive.google.com/file/d/${object.id}/view`}
                target="_blank"
              >
                <Folder color="info" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item sx={{ flexGrow: 1 }}>
          <InlineEdit
            defaultValue={object.title}
            onSave={(value) => api.setTitle(object.id, value)}
            fontSize={theme.typography.h5}
            onFocus={handleFocus}
            saveOnEnter
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <StarControl object={object} api={api} />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <HideControl object={object} api={api} />
      </Box>
    </Box>
  );
}
