import { FileData, FileAction, pushFailed, pushSucceeded } from "./file-state";
import { FileStore } from "./file-store";
import { SourceType } from "./source-state";
import { SourceStore } from "./source-store";

export interface BatchPushRequest {
  source: string;
  requests: PushRequest[];
}

export interface BatchPushResponse {
  responses: PushResponse[];
}

export interface PushRequest {
  id: string;
  data: FileData;
  revision: number;
  remoteExists: boolean;
  contentModified: boolean;
  metadataModified: boolean;
}
export interface PushResponse {
  id: string;
  success: boolean;
  revision: number;
  error?: string;
}
export type PushClient = (req: BatchPushRequest) => Promise<BatchPushResponse>;
async function getPushRequests(
  source: string,
  store: FileStore,
): Promise<BatchPushRequest> {
  const storeData = await store.listNeedsPush(source);
  const requests: PushRequest[] = [];
  for (const [id, state] of Object.entries(storeData)) {
    if (state?.data) {
      const request = {
        id,
        source: state.source,
        data: state.data,
        revision: state.revision,
        remoteExists: state.remoteExists,
        contentModified: state.push.contentModified,
        metadataModified: state.push.metadataModified,
      };
      requests.push(request);
    }
  }
  return { requests, source };
}
function pushResponseToAction(resp: PushResponse): FileAction {
  if (resp.success) {
    return pushSucceeded(resp.revision);
  } else {
    return pushFailed(resp.revision, resp.error ?? "Unknown error");
  }
}
export async function push(
  source: string,
  store: FileStore,
  client: PushClient,
) {
  const req = await getPushRequests(source, store);
  if (!req.requests.length) {
    return;
  }
  const responses = await client(req);
  for (const resp of responses.responses) {
    const action = pushResponseToAction(resp);
    await store.apply(resp.id, action);
  }
}

export async function alwaysSuccess(request: BatchPushRequest) {
  const responses = request.requests.map(({ id, revision }) => ({
    id,
    revision,
    success: true,
  }));
  return { responses };
}
export const alwaysFailure =
  (error: string) => async (request: BatchPushRequest) => {
    const responses = request.requests.map(({ id, revision }) => ({
      id,
      revision,
      success: false,
      error,
    }));
    return { responses };
  };

export type PushClientByType = {
  [type in SourceType]: PushClient;
};

function sourceTypeLookup(store: SourceStore) {
  return async function (request: BatchPushRequest) {
    const source = await store.get(request.source);
    return source?.type ?? SourceType.default;
  };
}

export function routingPushClient(
  store: SourceStore,
  clients: PushClientByType,
): PushClient {
  const typeLookup = sourceTypeLookup(store);
  return async function (
    request: BatchPushRequest,
  ): Promise<BatchPushResponse> {
    const type = await typeLookup(request);
    const client = clients[type];
    const response = client(request);
    return response;
  };
}
