import { PeriodicBackgroundJob } from "./background-jobs";
import { pull, PullClient } from "../pull";
import { gdrivePullClient } from "../google-drive/google-drive-pull";
import {
  gdriveGetContent,
  gdriveGetMetadata,
} from "../google-drive/google-drive-api";
import { defaultLogger, Logger, prefix } from "../logging";
import { AppFileSystem } from "../app-file-system";
import { AuthManager } from "../auth";

async function getPullClient(
  auth: AuthManager,
  logger: Logger,
): Promise<PullClient> {
  const getToken = async () => {
    const creds = await auth.getGoogle()();
    return creds.token;
  };
  const getMetadata = gdriveGetMetadata(getToken);
  const getContent = gdriveGetContent(getToken);
  return gdrivePullClient(getMetadata, getContent, logger);
}

export const pullJob = (
  auth: AuthManager,
  fs: AppFileSystem,
  logger: Logger = defaultLogger,
  limit = 100,
) => {
  logger = prefix(logger, "[Pull]");
  return new PeriodicBackgroundJob({
    name: "pull",
    delayMs: 10,
    job: async () => {
      if (!navigator.onLine) {
        return;
      }
      const sources = await fs.sources.list();
      for (const source of sources) {
        const client = await getPullClient(auth, logger);
        await pull(source, fs.files, client, limit);
      }
    },
  });
};
