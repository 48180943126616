/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { ChatMessage } from "../../db-chat-messages";

export function Message({
  data,
  onDelete,
}: {
  data: ChatMessage;
  onDelete: () => void;
}) {
  return (
    <Box padding={1} margin={1} bgcolor="lightblue">
      <Button onClick={onDelete}>Delete</Button>
      <Markdown
        components={{
          a: (props) => {
            if (props.href?.startsWith("http")) {
              return <a href={props.href}>{props.children}</a>;
            } else {
              return <Link to={props.href ?? ""}>{props.children}</Link>;
            }
          },
        }}
        remarkPlugins={[remarkGfm]}
      >
        {gptMessageToMd(data)}
      </Markdown>
    </Box>
  );
}

function messageText(message: ChatMessage) {
  const content = message.gptMessage.message.content;
  if (typeof content === "string") {
    return content;
  } else {
    return "";
  }
}

function gptMessageToMd(message: ChatMessage) {
  const gptMessage = message.gptMessage.message;
  const text = messageText(message);
  switch (gptMessage.role) {
    case "user":
      return text;
    case "assistant": {
      const parts = [];
      if (text) {
        parts.push(text);
      }
      const toolCalls = gptMessage.tool_calls ?? [];
      for (const toolCall of toolCalls) {
        parts.push(
          "```\n" +
            `${toolCall.function.name}(${toolCall.function.arguments})` +
            "\n```",
        );
      }
      return parts.join("\n");
    }
    case "tool":
      return "```\n" + text + "\n```";
    default:
      return "";
  }
}
