export class DefaultMap<V> {
  private map = new Map<string, V>();
  constructor(
    private getDefault: () => V,
    data?: Record<string, V>,
  ) {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        this.map.set(key, value);
      }
    }
  }
  set(key: string, value: V) {
    this.map.set(key, value);
  }
  get(key: string): V {
    const value = this.map.get(key);
    if (value === undefined) {
      const newVal = this.getDefault();
      this.map.set(key, newVal);
      return newVal;
    } else {
      return value;
    }
  }
  keys() {
    return Array.from(this.map.keys());
  }
  values() {
    return Array.from(this.map.values());
  }
  entries() {
    return Object.fromEntries(this.map.entries());
  }
}
