import { BindParams, Database, QueryExecResult } from "sql.js";
import {
  ColDef,
  ColumnData,
  PhysicalDb,
  RowData,
  Schema,
  TableData,
  toColType,
} from "./core";

export class SqlDb implements PhysicalDb {
  constructor(
    private db: Database,
    private idName: string,
    private chunkSize = 100,
  ) {}
  getTables(): string[] {
    const query = "SELECT tbl_name FROM sqlite_schema WHERE type = 'table'";
    const [result] = this.exec(query);
    if (!result) {
      return [];
    }
    const names: string[] = [];
    for (const row of result.values) {
      if (typeof row[0] === "string") {
        names.push(row[0]);
      }
    }
    return names;
  }
  getSchema(table: string): Schema {
    const info = this.getColumnInfo(table);
    return {
      getColumnDef(name) {
        const def = info.find((x) => x.name === name);
        if (!def) {
          throw new Error(`Column not found: ${name}`);
        }
        return def;
      },
      getColumns() {
        return info.map((x) => x.name);
      },
    };
  }
  getTableData(table: string, rowIds?: string[] | undefined): TableData {
    let query: string;
    let bindParams: BindParams | undefined;
    if (rowIds && rowIds.length > 0) {
      const params = rowIds.map(() => "?");
      const paramList = params.join(", ");
      query = `SELECT * FROM ${table} WHERE ${this.idName} IN (${paramList})`;
      bindParams = rowIds;
    } else {
      query = `SELECT * FROM ${table}`;
      bindParams = undefined;
    }
    const tableData: TableData = {};
    const [results] = this.exec(query, bindParams);
    if (!results) {
      return tableData;
    }
    const columns = results.columns;
    const idIdx = columns.findIndex((x) => x === this.idName);
    if (idIdx < 0) {
      throw new Error(`Expected id column ${this.idName} in result set`);
    }
    for (const values of results.values) {
      const rowId = values[idIdx];
      if (typeof rowId !== "string") {
        throw new Error(
          `Expected id column ${
            this.idName
          } to be string, but received ${typeof rowId}`,
        );
      }
      tableData[rowId] = {};
      for (let i = 0; i < values.length; i++) {
        if (i === idIdx) {
          continue;
        }
        const column = columns[i];
        tableData[rowId][column] = values[i];
      }
    }
    return tableData;
  }
  getColumnData(table: string, column: string): TableData {
    const tableData: TableData = {};
    const query = `SELECT ${this.idName}, ${column} FROM ${table}`;
    const [results] = this.exec(query);
    if (!results) {
      return tableData;
    }
    const values = results.values;
    for (let i = 0; i < values.length; i++) {
      const [rowId, value] = values[i];
      if (typeof rowId !== "string") {
        throw new Error(
          `Expected id column ${
            this.idName
          } to be string, but received ${typeof rowId}`,
        );
      }
      tableData[rowId] = { [column]: value };
    }
    return tableData;
  }
  getRowIds(table: string): string[] {
    const query = `SELECT ${this.idName} FROM ${table}`;
    const [result] = this.exec(query);
    const rowIds: string[] = [];
    if (!result) {
      return rowIds;
    }
    for (let i = 0; i < result.values.length; i++) {
      const [rowId] = result.values[i];
      if (typeof rowId !== "string") {
        throw new Error(
          `Expected id column ${
            this.idName
          } to be string, but received ${typeof rowId}`,
        );
      }
      rowIds.push(rowId);
    }
    return rowIds;
  }
  createTable(table: string, schema: Schema): boolean {
    const columns = schema.getColumns();
    for (const colName of schema.getColumns()) {
      if (colName === this.idName) {
        throw new Error(
          `Cannot include id field ${this.idName} in schema. It will be automatically created.`,
        );
      }
    }
    const defCmds = columns
      .map((c) => schema.getColumnDef(c))
      .map((x) => `${x.name} ${x.type}`);
    const idDef = `${this.idName} text primary key`;
    const colDefList = [idDef, ...defCmds];
    const defCmd = colDefList.join(", ");
    const query = `CREATE TABLE ${table} (${defCmd})`;
    this.exec(query);
    return true;
  }
  dropTable(table: string): boolean {
    this.exec(`DROP TABLE ${table}`);
    return true;
  }
  addColumn(table: string, column: ColDef): boolean {
    if (column.name === this.idName) {
      throw new Error(
        `Cannot add id column ${this.idName}. It will be automatically created.`,
      );
    }
    const { name, type } = column;
    const query = `ALTER TABLE ${table} ADD COLUMN ${name} ${type}`;
    this.exec(query);
    return true;
  }
  dropColumn(table: string, column: string): boolean {
    if (column === this.idName) {
      throw new Error(`Cannot drop ${this.idName}`);
    }
    const query = `ALTER TABLE ${table} DROP COLUMN ${column}`;
    this.exec(query);
    return true;
  }
  upsertTableData(table: string, data: TableData): boolean {
    const { idName, chunkSize } = this;
    const rowIds = Object.keys(data);
    if (rowIds.length === 0) {
      return false;
    }
    let changed = false;
    for (let i = 0; i < rowIds.length; i += chunkSize) {
      for (const rowId of rowIds.slice(i, i + chunkSize)) {
        const rowData = data[rowId];
        const columns = Object.keys(rowData);
        if (columns.includes(idName)) {
          throw new Error(`Column names cannot include ${idName}`);
        }
        if (columns.length === 0) {
          const query = `INSERT INTO ${table} (${idName}) VALUES (?) ON CONFLICT DO NOTHING`;
          const params = [rowId];
          this.exec(query, params);
          changed = this.db.getRowsModified() > 0 || changed;
        } else {
          const fullCols = [idName, ...columns];
          const colList = fullCols.join(", ");
          const valList = fullCols.map(() => "?").join(", ");
          const setList = columns.map((col) => `${col} = ?`).join(", ");
          const whereList = columns
            .map((col) => `${col} IS NOT ?`)
            .join(" OR ");
          const query = `
            INSERT INTO ${table} (${colList})
            VALUES (${valList})
            ON CONFLICT (${idName}) DO
            UPDATE SET ${setList} WHERE ${whereList}`;
          const values = columns.map((x) => rowData[x]);
          const params = [rowId, ...values, ...values, ...values];
          this.exec(query, params);
          const mod = this.db.getRowsModified();
          changed = mod > 0 || changed;
        }
      }
    }
    return changed;
  }
  updateColumnData(table: string, column: string, data: ColumnData): boolean {
    for (const [rowId, columnValue] of Object.entries(data)) {
      const query = `UPDATE ${table} SET ${column} = ? WHERE ${this.idName} = ?`;
      const params = [columnValue, rowId];
      this.exec(query, params);
    }
    return true;
  }
  deleteRows(table: string, rowIds: string[]): boolean {
    const paramList = rowIds.map(() => "?").join(", ");
    const query = `DELETE FROM ${table} WHERE ${this.idName} IN (${paramList})`;
    this.exec(query, rowIds);
    return this.db.getRowsModified() > 0;
  }
  updateRow(table: string, rowId: string, rowData: RowData): boolean {
    const columns = Object.keys(rowData);
    const setQuery = columns.map((x) => `${x} = ?`).join(", ");
    const query = `UPDATE ${table} SET ${setQuery} WHERE ${this.idName} = ?`;
    const colValues = columns.map((x) => rowData[x]);
    const params = [...colValues, rowId];
    this.exec(query, params);
    return true;
  }
  exec(query: string, params?: BindParams): QueryExecResult[] {
    //const start = performance.now();
    const results = this.db.exec(query, params);
    //console.log(performance.now() - start, { query, params });
    return results;
  }
  private getColumnInfo(table: string) {
    const [result] = this.exec(`PRAGMA table_info(${table})`);
    if (!result) {
      return [];
    }
    const values = result.values
      .map((x) => {
        if (typeof x[1] === "string" && typeof x[2] === "string") {
          const name = x[1];
          const type = toColType(x[2]);
          if (!type) {
            throw new Error(`Unexpected column type ${type}`);
          }
          return { name, type };
        }
      })
      .filter((x): x is ColDef => !!x)
      .filter((x) => x.name !== this.idName);
    return values;
  }
}
