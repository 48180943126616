import { markVisited, requestCrawl, requestPull } from "../file-state";
import { FileStore } from "../file-store";
import { defaultLogger, Logger } from "../logging";
import { SourceType } from "../source-state";
import { SourceStore } from "../source-store";
import { Search, SearchRequest, SearchResultItem } from "./google-drive-api";
import { GDriveCrawlerStore } from "./google-drive-crawler-store";
import { idPrefix } from "./google-drive-logger";

export type ListFolder = (id: string) => AsyncIterable<SearchResultItem>;

function listChildrenRequest(id: string, pageToken?: string): SearchRequest {
  return {
    type: "search",
    query: `'${id}' in parents and trashed=false`,
    pageToken,
  };
}

export function folderLister(
  search: Search,
  crawlerStore: GDriveCrawlerStore,
  logger: Logger = defaultLogger,
): ListFolder {
  return async function* (id: string) {
    logger = idPrefix(logger, id);
    logger("Listing folder");
    let nextPageToken = await crawlerStore.getNextPageToken(id);
    do {
      const req = listChildrenRequest(id, nextPageToken);
      const resp = await search(req);
      if (resp.result === "failure") {
        logger(`Could not list folder: ${resp.error}`);
        throw new Error("could not list folder");
      }
      for (const item of resp.items) {
        yield item;
      }
      nextPageToken = resp.nextPageToken;
      await crawlerStore.setNextPageToken(id, nextPageToken);
    } while (nextPageToken);
    await crawlerStore.setNextPageToken(id, undefined);
  };
}

export function gdriveCrawler(
  sourceStore: SourceStore,
  fileStore: FileStore,
  crawlerStore: GDriveCrawlerStore,
  search: Search,
  logger: Logger,
) {
  const list = folderLister(search, crawlerStore, logger);
  return async function () {
    const storeData = await sourceStore.getByType(SourceType.gdrive);
    for (const sourceId of Object.keys(storeData)) {
      const storeData = await fileStore.listNeedsCrawl(sourceId);
      for (const id of Object.keys(storeData)) {
        const idLogger = idPrefix(logger, id);
        for await (const child of list(id)) {
          if (child.contentType === "application/vnd.google-apps.folder") {
            idLogger(`Discovered folder ${child.id.substring(0, 8)}`);
            await fileStore.apply(child.id, requestCrawl(sourceId));
          } else {
            idLogger(`Discovered file ${child.id.substring(0, 8)}`);
            await fileStore.apply(child.id, requestPull(sourceId));
          }
        }
        idLogger(`Marking crawled`);
        await fileStore.apply(id, markVisited());
      }
    }
  };
}
