import { Container } from "@mui/system";
import React, { useCallback } from "react";
import { FullScreenDropzone } from "./FileDropZone";
import { ListViewContent, useListViewState } from "./ListView";
import {
  useDbQueries,
  useFpApi,
  useObjectNavigate,
  usePasteFiles,
} from "./notes-hooks";

const pageSize = 100;
export function NotesMain() {
  const api = useFpApi();
  const addFiles = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        await api.addFile(file);
      }
    },
    [api],
  );
  const queries = useDbQueries();
  const getObjects = useCallback(
    (_query: string, page: number) =>
      queries.getObjects(pageSize, pageSize * page),
    [queries],
  );
  const { objects, input, loadNextPage, selection } =
    useListViewState(getObjects);
  usePasteFiles(addFiles);
  const navigate = useObjectNavigate();
  return (
    <FullScreenDropzone
      onDrop={addFiles}
      style={{ width: "100%", height: "100%" }}
    >
      <ListViewContent
        input={input}
        objects={objects}
        noHits={() => <Container>No objects</Container>}
        selection={selection}
        loadNextPage={loadNextPage}
        onSelect={(object) => navigate(object.id)}
        getPrimaryText={(object) => object.title}
        getSecondaryText={(object) => object.text}
      />
    </FullScreenDropzone>
  );
}
