import React, { useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { FpApi, ObjectListItem } from "../fp-api";
import { useFpApi } from "../notes-hooks";
import { ListEditor, ListEditorProps } from "./editor/ListEditor";

export function RelatedTagsView({ id }: { id: string }) {
  const api = useFpApi();
  const navigate = useNavigate();
  const props = useMemo(() => getProps(id, api, navigate), [id, api, navigate]);
  return <ListEditor {...props} />;
}

const pageSize = 100;
function getProps(
  id: string,
  api: FpApi,
  navigate: NavigateFunction,
): ListEditorProps<ObjectListItem> {
  return {
    getObjects: ({ input, page, queries }) =>
      queries
        .searchObjects(input, pageSize, page * pageSize)
        .filter((x) => x.id !== id),
    getSelectedObjects: ({ input, queries }) =>
      queries.objectsWithTag(id, input).filter((x) => x.id !== id),
    getObjectId: (x) => x.id,
    getPrimaryText: (x) => x.title,
    getSecondaryText: (x) => x.text,
    toggle: (x) =>
      api.hasTag(x.id, id)
        ? api.deleteTagWhere(x.id, id)
        : api.addTag(x.id, id),
    add: ({ input, queries }) => {
      const objectId = api.addNote(input, "");
      api.addTag(objectId, id);
      return queries.getObject(objectId);
    },
    getAddText: (query) => <>Add a new note called &ldquo;{query}&rdquo;</>,
    navigate: (x) => navigate(`/notes/note/${x.id}`),
  };
}
