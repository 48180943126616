import {
  AffectedRow,
  ColDef,
  Operation,
  projectTableData,
  RowData,
  schemaFrom,
  selectTableData,
  TableData,
  WritableDb,
} from "./core";

export interface AddColumnMessage {
  type: "addColumn";
  table: string;
  colDef: ColDef;
}
export class AddColumnOp implements Operation {
  constructor(
    private table: string,
    private colDef: ColDef,
  ) {}
  apply(vdb: WritableDb): boolean {
    const { table, colDef } = this;
    return vdb.addColumn(table, colDef);
  }
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean {
    const { table, colDef } = this;
    const beforeSchema = virtualDb.getSchema(table);
    const vTables = virtualDb.getTables();
    virtualDb.addColumn(table, colDef);
    const existsAfter = virtualDb
      .getSchema(table)
      .getColumns()
      .includes(colDef.name);
    const afterSchema = virtualDb.getSchema(table);
    if (!existsAfter) {
      return false;
    }
    if (!vTables.includes(table)) {
      return false;
    }
    if (!beforeSchema.getColumns().includes(colDef.name)) {
      physicalDb.addColumn(table, colDef);
      const data = virtualDb.getColumnData(table, colDef.name);
      physicalDb.upsertTableData(table, data);
      return true;
    }
    const beforeDef = beforeSchema.getColumnDef(colDef.name);
    const afterDef = afterSchema.getColumnDef(colDef.name);
    if (beforeDef.type !== afterDef.type) {
      physicalDb.dropColumn(table, colDef.name);
      physicalDb.addColumn(table, colDef);
      const data = virtualDb.getColumnData(table, colDef.name);
      physicalDb.upsertTableData(table, data);
      return true;
    }
    return false;
  }
  toString() {
    return JSON.stringify(this.serialize());
  }
  serialize(): AddColumnMessage {
    const { table, colDef } = this;
    return { type: "addColumn", table, colDef };
  }
  toJSON() {
    return this.serialize();
  }
  affectedRows(): AffectedRow[] {
    return [];
  }
}

export interface CreateTableMessage {
  type: "createTable";
  table: string;
  columns: ColDef[];
}
export class CreateTableOp implements Operation {
  constructor(
    private table: string,
    private columns: ColDef[],
  ) {}
  apply(vdb: WritableDb): boolean {
    const { table, columns } = this;
    const schema = schemaFrom(columns);
    return vdb.createTable(table, schema);
  }
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean {
    const { table } = this;
    const beforeSchema = virtualDb.getSchema(table);
    const tableExistedBefore = virtualDb.getTables().includes(table);
    if (!this.apply(virtualDb)) {
      return false;
    }
    const afterSchema = virtualDb.getSchema(table);
    const tableExistsAfter = virtualDb.getTables().includes(table);
    if (!tableExistsAfter) {
      return false;
    }
    if (!tableExistedBefore) {
      const schema = virtualDb.getSchema(table);
      physicalDb.createTable(table, schema);
      physicalDb.upsertTableData(table, virtualDb.getTableData(table));
      return true;
    }
    const beforeCols = new Set(beforeSchema.getColumns());
    const afterCols = new Set(afterSchema.getColumns());
    const toDrop = [...beforeCols].filter((x) => !afterCols.has(x));
    for (const col of toDrop) {
      physicalDb.dropColumn(table, col);
    }
    const toAdd = [...afterCols].filter((x) => !beforeCols.has(x));
    for (const col of toAdd) {
      physicalDb.addColumn(table, afterSchema.getColumnDef(col));
      const data = virtualDb.getTableData(table);
      const projected = projectTableData(data, [col]);
      physicalDb.upsertTableData(table, projected);
    }
    let reconciled = false;
    const toReconcile = [...afterCols].filter((x) => beforeCols.has(x));
    for (const col of toReconcile) {
      const beforeType = beforeSchema.getColumnDef(col).type;
      const afterType = afterSchema.getColumnDef(col).type;
      if (beforeType !== afterType) {
        const data = virtualDb.getTableData(table);
        const projected = projectTableData(data, [col]);
        physicalDb.dropColumn(table, col);
        physicalDb.addColumn(table, afterSchema.getColumnDef(col));
        physicalDb.upsertTableData(table, projected);
        reconciled = true;
      }
    }
    return toAdd.length > 0 || toDrop.length > 0 || reconciled;
  }
  toString() {
    return JSON.stringify(this.serialize());
  }
  serialize(): CreateTableMessage {
    const { table, columns } = this;
    return { type: "createTable", table, columns };
  }
  toJSON() {
    return this.serialize();
  }
  affectedRows(): AffectedRow[] {
    return [];
  }
}

export interface DeleteRowsMessage {
  type: "deleteRows";
  table: string;
  rowIds: string[];
}
export class DeleteRowsOp implements Operation {
  constructor(
    private table: string,
    private rowIds: string[],
  ) {}
  apply(db: WritableDb): boolean {
    const { table, rowIds } = this;
    return db.deleteRows(table, rowIds);
  }
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean {
    const { table, rowIds } = this;
    if (rowIds.length === 0) {
      return false;
    }
    if (!this.apply(virtualDb)) {
      return false;
    }
    if (!virtualDb.getTables().includes(table)) {
      return false;
    }
    const existing = new Set(virtualDb.getRowIds(table));
    const actualDeletes = [...rowIds].filter((x) => !existing.has(x));
    if (actualDeletes.length === 0) {
      return false;
    }
    return physicalDb.deleteRows(table, [...actualDeletes]);
  }
  toString() {
    return JSON.stringify(this.serialize());
  }
  serialize(): DeleteRowsMessage {
    const { table, rowIds } = this;
    return { type: "deleteRows", table, rowIds };
  }
  toJSON() {
    return this.serialize();
  }
  affectedRows(): AffectedRow[] {
    return this.rowIds.map((row) => ({ table: this.table, row }));
  }
}

export interface DropColumnMessage {
  type: "dropColumn";
  table: string;
  column: string;
}
export class DropColumnOp implements Operation {
  constructor(
    private table: string,
    private column: string,
  ) {}
  apply(db: WritableDb): boolean {
    const { table, column } = this;
    return db.dropColumn(table, column);
  }
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean {
    const { table, column } = this;
    const vColumns = virtualDb.getSchema(table).getColumns();
    const vTables = virtualDb.getTables();
    if (!this.apply(virtualDb)) {
      return false;
    }
    if (!vTables.includes(table)) {
      return false;
    }
    if (!vColumns.includes(column)) {
      return false;
    }
    physicalDb.dropColumn(table, column);
    return true;
  }
  toString() {
    return JSON.stringify(this.serialize());
  }
  serialize(): DropColumnMessage {
    const { table, column } = this;
    return { type: "dropColumn", table, column };
  }
  toJSON() {
    return this.serialize();
  }
  affectedRows(): AffectedRow[] {
    return [];
  }
}

export interface DropTableMessage {
  type: "dropTable";
  table: string;
}
export class DropTableOp implements Operation {
  constructor(private table: string) {}
  apply(vdb: WritableDb): boolean {
    return vdb.dropTable(this.table);
  }
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean {
    const { table } = this;
    const tableExisted = virtualDb.getTables().includes(table);
    if (!this.apply(virtualDb)) {
      return false;
    }
    if (!tableExisted) {
      return false;
    }
    physicalDb.dropTable(table);
    return true;
  }
  toString() {
    return JSON.stringify(this.serialize());
  }
  serialize(): DropTableMessage {
    const { table } = this;
    return { type: "dropTable", table };
  }
  toJSON() {
    return this.serialize();
  }
  affectedRows(): AffectedRow[] {
    return [];
  }
}

export interface UpsertTableDataMessage {
  type: "upsertTableData";
  table: string;
  data: TableData;
}
export class UpsertTableDataOp implements Operation {
  constructor(
    private table: string,
    private data: TableData,
  ) {}
  apply(db: WritableDb): boolean {
    const { table, data } = this;
    return db.upsertTableData(table, data);
  }
  applyAndReconcile(virtualDb: WritableDb, physicalDb: WritableDb): boolean {
    const { table, data } = this;
    const beforeData = virtualDb.getTableData(table, Object.keys(data));
    if (!this.apply(virtualDb)) {
      return false;
    }
    if (!virtualDb.getTables().includes(table)) {
      return false;
    }
    const columns = virtualDb.getSchema(table).getColumns();
    const rowIds = virtualDb.getRowIds(table);
    const afterData = virtualDb.getTableData(table, Object.keys(data));
    const afterProjected = projectTableData(afterData, columns);
    const afterSelected = selectTableData(afterProjected, rowIds);
    const beforeProjected = projectTableData(beforeData, columns);
    const beforeSelected = selectTableData(beforeProjected, rowIds);
    const toUpsert: TableData = {};
    for (const [rowId, afterRowData] of Object.entries(afterSelected)) {
      const beforeRowData = beforeSelected[rowId];
      if (!beforeRowData) {
        toUpsert[rowId] = afterRowData;
        continue;
      }
      const diffed: RowData = {};
      for (const col of columns) {
        const before = beforeRowData[col];
        const after = afterRowData[col];
        if (before !== after || !beforeRowData[rowId]) {
          diffed[col] = after;
        }
      }
      toUpsert[rowId] = diffed;
    }
    return physicalDb.upsertTableData(table, toUpsert);
  }
  toString() {
    return JSON.stringify(this.serialize());
  }
  toJSON() {
    return this.serialize();
  }
  serialize(): UpsertTableDataMessage {
    const { table, data } = this;
    return { type: "upsertTableData", table, data };
  }
  affectedRows(): AffectedRow[] {
    return Object.keys(this.data).map((row) => ({ table: this.table, row }));
  }
}
