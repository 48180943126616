export enum SourceType {
  default = "default",
  gdrive = "gdrive",
}

export interface SourceState {
  readonly name: string;
  readonly type: SourceType;
  readonly isDeleted: boolean;
  readonly config: unknown;
  readonly docStoreFile?: string;
  readonly dbFile?: string;
}

export const defaultSource: SourceState = {
  name: "default",
  type: SourceType.default,
  isDeleted: false,
  config: undefined,
};

export type SourceAction = (current?: SourceState | undefined) => SourceState;
export function addSource(
  name: string,
  type: SourceType,
  config: unknown,
): SourceAction {
  return function (current?: SourceState) {
    if (current) {
      throw new Error("source already exists");
    }
    return { name, type, isDeleted: false, config };
  };
}

export function deleteSource(): SourceAction {
  return function (current?: SourceState) {
    if (!current) {
      throw new Error("source not found");
    }
    return { ...current, isDeleted: true };
  };
}

export function configureSource(config: unknown): SourceAction {
  return function (current?: SourceState) {
    if (!current) {
      throw new Error("source not found");
    }
    return { ...current, config };
  };
}

export function compose(first: SourceAction, ...rest: SourceAction[]) {
  return function (current?: SourceState) {
    let result = first(current);
    for (const action of rest) {
      result = action(result);
    }
    return result;
  };
}

export function setPersistenceFile(docStoreFile: string) {
  return function (current?: SourceState) {
    if (!current) {
      throw new Error("not found");
    }
    return {
      ...current,
      docStoreFile,
    };
  };
}

export function setDbPersistenceFile(dbFile: string) {
  return function (current?: SourceState) {
    if (!current) {
      throw new Error("not found");
    }
    return {
      ...current,
      dbFile,
    };
  };
}
