import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Data, Network } from "vis-network";
import { appBarHeight } from "../../LayoutAppBar";
import { ObjectDetails } from "../fp-api";
import { useDbQueries } from "../notes-hooks";

export function NetworkView({ id }: { id: string; object: ObjectDetails }) {
  const queries = useDbQueries(false);
  const { objects, edges } = queries.networkView(id);
  const nodes = objects.map((x) => ({
    id: x.id,
    label: wordWrap(x.title, 20),
    shape: "box",
    group: x.cluster,
  }));
  return <VisJsNetwork data={{ nodes, edges }} />;
}

function VisJsNetwork({ data }: { data: Data }) {
  const visJsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!visJsRef.current) {
      return;
    }
    const total = visJsRef.current.parentElement?.offsetHeight ?? 0;
    const height = total - appBarHeight;
    const options = {
      height: `${height}px`,
      physics: { enabled: true, stabilization: { iterations: 10 } },
    };
    const network = new Network(visJsRef.current, data, options);
    network.on(
      "doubleClick",
      (x: any) =>
        x.nodes.length && navigate(`/notes/note/${x.nodes[0]}#network`),
    );
    return () => network.destroy();
  }, [visJsRef, data, navigate]);
  return (
    <Box
      ref={visJsRef}
      sx={{
        flexGrow: 1,
        flexDirection: "row",
      }}
    ></Box>
  );
}

function wordWrap(str: string, maxWidth: number) {
  if (!str) return "";
  const newLineStr = "\n";
  let res = "";
  while (str.length > maxWidth) {
    let found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join("");
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join("");
      str = str.slice(maxWidth);
    }
  }
  return res + str;
}

function testWhite(x: string) {
  const white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
}
