import React from "react";
import Dropzone from "react-dropzone";

export interface FileDropZone {
  onDrop: (files: File[]) => void;
  style?: React.CSSProperties;
}
export function FullScreenDropzone(
  props: React.PropsWithChildren<FileDropZone>,
) {
  const { onDrop, style } = props;
  return (
    <Dropzone onDrop={onDrop} noClick>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} style={style}>
          {isDragActive ? (
            <div
              style={{
                opacity: 0.5,
                backgroundColor: "#60a7dc",
                width: "100%",
                height: "100%",
                zIndex: 1000,
                position: "fixed",
                left: 0,
                top: 0,
              }}
            ></div>
          ) : null}
          <input {...getInputProps()} hidden />
          {props.children}
        </div>
      )}
    </Dropzone>
  );
}
