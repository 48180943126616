import { FromSchema } from "json-schema-to-ts";
import { addSeconds } from "../../data/google-drive/google-drive-auth";
import { FpApi } from "./fp-api";
import { makeValidator } from "./schema";

const youTubeInfoSchema = {
  type: "object",
  properties: {
    token: {
      type: "string",
    },
    expires: {
      type: "string",
    },
  },
  required: ["token", "expires"],
} as const;
export type YouTubeInfo = FromSchema<typeof youTubeInfoSchema>;
export type YouTubeAuth = Pick<YouTubeInfo, "token" | "expires">;
const isYouTubeInfo = makeValidator<YouTubeInfo>(youTubeInfoSchema);

const noteName = "YouTube Info";
export async function getYouTubeAuth(fpApi: FpApi): Promise<YouTubeAuth> {
  const obj = fpApi.getOrCreateObjectByTitle(noteName);
  if (isYouTubeInfo(obj.properties)) {
    const expires = new Date(obj.properties.expires);
    if (expires > new Date()) {
      return obj.properties;
    }
    const refreshed = await refreshYoutubeAuth();
    fpApi.updateObjectProperties(obj.id, refreshed);
    return refreshed;
  }
  const auth = await refreshYoutubeAuth();
  fpApi.updateObjectProperties(obj.id, auth);
  return auth;
}

declare const GOOGLE_CLIENT_ID: string;

async function refreshYoutubeAuth() {
  const scopes = ["https://www.googleapis.com/auth/youtube"];
  if (!navigator.onLine) {
    throw new Error("You must be online to authenticate");
  }
  const scope = scopes.join(" ");
  return new Promise((resolve: (auth: YouTubeAuth) => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      scope,
      prompt: "",
      select_account: false,
      callback: (resp: any) => {
        const token = resp.access_token as string;
        const expires = addSeconds(new Date(), resp.expires_in);
        resolve({ token, expires: expires.toISOString() });
      },
    });
    tokenClient.requestAccessToken();
  });
}

function youTubeNoteId(id: string) {
  return `youtube:${id}`;
}

export async function getLikedVideosNoNotes(api: FpApi) {
  const auth = await getYouTubeAuth(api);
  const videos = await getLikedVideos(auth);
  const noteIds = videos.map((v) => youTubeNoteId(v.id));
  const existing = new Set(
    api.getObjectsWithIds(noteIds, true).map((x) => x.id),
  );
  const results: Video[] = [];
  for (const video of videos) {
    if (!existing.has(youTubeNoteId(video.id))) {
      results.push(video);
    }
  }
  return results;
}

export async function addYouTubeVideoNote(api: FpApi, video: Video) {
  const noteId = youTubeNoteId(video.id);
  api.addNoteWithId(
    noteId,
    video.title,
    `https://www.youtube.com/watch?v=${video.id}`,
  );
}

export async function skipYouTubeVideoNote(api: FpApi, video: Video) {
  const noteId = youTubeNoteId(video.id);
  api.addNoteWithId(noteId, video.title, "");
  api.setObjectVisibility(noteId, "trashed");
}

export interface Video {
  likedDate: Date;
  title: string;
  id: string;
}
async function getLikedVideos(auth: YouTubeAuth) {
  const results: Video[] = [];
  let pageToken: string | undefined = undefined;
  do {
    const resp = await getLikedVideosPage(auth, pageToken);
    const vids = resp.items.map((item: any) => {
      const likedDate = new Date(item.snippet.publishedAt);
      const title = item.snippet.title;
      const id = item.snippet.resourceId.videoId;
      return { likedDate, title, id };
    });
    results.push(...vids);
    pageToken = resp.nextPageToken;
  } while (pageToken);
  return results;
}

async function getLikedVideosPage(auth: YouTubeAuth, pageToken?: string) {
  const maxResults = 50;
  const params = new URLSearchParams({
    part: "snippet",
    playlistId: "LL",
    access_token: auth.token,
    maxResults: maxResults.toString(),
  });
  if (pageToken) {
    params.set("pageToken", pageToken);
  }
  const resp = await fetch(
    `https://youtube.googleapis.com/youtube/v3/playlistItems?${params.toString()}`,
  );
  const json = await resp.json();
  return json;
}
