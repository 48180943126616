import { DbManager } from "../app-sql-api";
import { Logger, prefix } from "../logging";
import { SourceStore } from "../source-store";
import { PeriodicBackgroundJob } from "./background-jobs";

export function snapshotDbJob(
  sourceStore: SourceStore,
  dbManager: DbManager,
  logger: Logger,
) {
  return new PeriodicBackgroundJob({
    name: "snapshot-db",
    job: async () => {
      const sourceIds = await sourceStore.list();
      for (const sourceId of sourceIds) {
        const db = dbManager.getDb(sourceId);
        if (!db) {
          continue;
        }
        const prefixed = prefix(logger, `Sql backup for ${sourceId}: `);
        await db.onSnapshot(prefixed);
      }
      return;
    },
    delayMs: 5000,
  });
}
