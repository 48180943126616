import { GDriveChangeWatcherDb, GDriveChangeWatcherRecord } from "../app-db";
import { defaultLogger, Logger } from "../logging";
import { GetMetadata, getMetadataRequest } from "./google-drive-api";
import { idPrefix } from "./google-drive-logger";

async function getInitialStartPageToken(token: string) {
  const url = "https://www.googleapis.com/drive/v3/changes/startPageToken";
  const resp = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await resp.json();
  return json.startPageToken as string;
}

interface ListChangesPage {
  fileIds: string[];
  newStartPageToken?: string;
  nextPageToken?: string;
}

async function listChanges(
  authToken: string,
  startPageToken: string,
): Promise<ListChangesPage> {
  const url = `https://www.googleapis.com/drive/v3/changes?pageToken=${startPageToken}`;
  const resp = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  const { nextPageToken, newStartPageToken, changes } = await resp.json();
  const fileIds = (changes as any[]).map((x) => x.fileId);
  return { nextPageToken, newStartPageToken, fileIds };
}

export interface GDriveChangeWatcherState {
  pageToken: string;
}

const defaultId = "gdrive";
async function getStartPageToken(
  db: GDriveChangeWatcherDb,
): Promise<string | undefined> {
  const record = await db.gdriveChangeWatcher.get(defaultId);
  return record?.pageToken;
}

async function setStartPageToken(
  db: GDriveChangeWatcherDb,
  pageToken: string,
): Promise<void> {
  const record: GDriveChangeWatcherRecord = { pageToken, id: defaultId };
  await db.gdriveChangeWatcher.put(record);
}

export async function getDriveChanges(
  authToken: string,
  db: GDriveChangeWatcherDb,
  logger: Logger = defaultLogger,
): Promise<string[]> {
  let startPageToken = await getStartPageToken(db);
  if (!startPageToken) {
    startPageToken = await getInitialStartPageToken(authToken);
  }
  const results: string[] = [];
  let nextPageToken: string | undefined = startPageToken;
  logger("Checking GDrive for changes");
  while (nextPageToken) {
    const page: ListChangesPage = await listChanges(authToken, nextPageToken);
    for (const fileId of page.fileIds) {
      results.push(fileId);
    }
    nextPageToken = page.nextPageToken;
    if (page.nextPageToken) {
      nextPageToken = page.nextPageToken;
    } else {
      startPageToken = page.newStartPageToken;
      break;
    }
  }
  if (startPageToken) {
    await setStartPageToken(db, startPageToken);
  }
  return results;
}

const root = "root";
function getParentClient(getMetadata: GetMetadata, logger: Logger) {
  return async function (id: string) {
    logger = idPrefix(logger, id);
    logger("Getting metadata for parent lookup");
    const resp = await getMetadata(getMetadataRequest(id));
    if (resp.result === "failure") {
      logger(`Failed to get metadata for parent lookup: ${resp.error}`);
      throw new Error("could not get parent: " + resp.error);
    }
    const { parents } = resp.metadata;
    if (!parents || parents.length === 0) {
      return root;
    }
    return parents[0];
  };
}

export type FolderContains = (
  folderId: string,
  fileId: string,
) => Promise<boolean>;

export function folderContainsFileClient(
  getMetadata: GetMetadata,
  logger: Logger = defaultLogger,
): FolderContains {
  const getParent = getParentClient(getMetadata, logger);
  const cache: { [id: string]: string } = {};
  const cachedGetParent = async (id: string) => {
    if (cache[id]) {
      return cache[id];
    }
    const parent = await getParent(id);
    cache[id] = parent;
    return parent;
  };
  async function folderContains(
    folderId: string,
    fileId: string,
  ): Promise<boolean> {
    const parent = await cachedGetParent(fileId);
    if (folderId === parent) {
      return true;
    } else if (parent === "root") {
      return false;
    } else {
      return folderContains(folderId, parent);
    }
  }
  return folderContains;
}
