import {
  GDriveCreateMetadata,
  GDriveUpdateMetadata,
  GoogleDriveMetadata,
  toUpdateable,
  toWriteable,
} from "./google-drive-metadata";

export interface SearchRequest {
  type: "search";
  query: string;
  pageToken?: string;
}

export interface SearchResultItem {
  id: string;
  contentType: string;
}
export function searchResultItem(id: string, contentType: string) {
  return { id, contentType };
}

export type SearchResponse =
  | {
      type: "search";
      result: "success";
      items: SearchResultItem[];
      nextPageToken?: string;
    }
  | {
      type: "search";
      result: "failure";
      error: string;
    };

export function searchRequest(
  query: string,
  pageToken?: string,
): SearchRequest {
  return { query, pageToken, type: "search" };
}

export function searchResponseSuccess(
  items: SearchResultItem[],
  nextPageToken?: string,
): SearchResponse {
  return { items, nextPageToken, type: "search", result: "success" };
}

export type Search = (req: SearchRequest) => Promise<SearchResponse>;

export function searchResponseFailure(error: string): SearchResponse {
  return { error, result: "failure", type: "search" };
}

export interface GetMetadataRequest {
  type: "get-metadata";
  id: string;
}
export function getMetadataRequest(id: string): GetMetadataRequest {
  return { type: "get-metadata", id };
}

export type GetContentRequest = {
  type: "get-content";
  id: string;
  contentType: string;
};
export function getContentRequest(
  id: string,
  contentType: string,
): GetContentRequest {
  return { id, contentType, type: "get-content" };
}

export type GetContentResponse =
  | {
      type: "get-content";
      result: "success";
      content: ArrayBuffer;
    }
  | {
      type: "get-content";
      result: "failure";
      error: string;
    };
export function getContentSuccess(content: ArrayBuffer): GetContentResponse {
  return { type: "get-content", result: "success", content };
}
export function getContentFailure(error: string): GetContentResponse {
  return { type: "get-content", result: "failure", error };
}
export type GetContent = (
  req: GetContentRequest,
) => Promise<GetContentResponse>;

export type GetMetadataResponse =
  | {
      type: "get-metadata";
      result: "success";
      metadata: GoogleDriveMetadata;
    }
  | {
      type: "get-metadata";
      result: "failure";
      error: string;
    };
export function getMetadataSuccess(
  metadata: GoogleDriveMetadata,
): GetMetadataResponse {
  return {
    type: "get-metadata",
    result: "success",
    metadata,
  };
}
export function getMetadataFailure(error: string): GetMetadataResponse {
  return {
    type: "get-metadata",
    result: "failure",
    error,
  };
}
export type GetMetadata = (
  request: GetMetadataRequest,
) => Promise<GetMetadataResponse>;

export interface CreateRequest {
  type: "create";
  metadata: GDriveCreateMetadata;
}
export function gdriveCreateRequest(
  id: string,
  metadata: GoogleDriveMetadata,
): CreateRequest {
  return {
    type: "create",
    metadata: toWriteable(metadata),
  };
}
export type CreateResponse =
  | {
      type: "created";
    }
  | {
      type: "already_exists";
    }
  | {
      type: "failed";
      error: string;
    };
export function createAlreadyExists(): CreateResponse {
  return { type: "already_exists" };
}
export function createSucceeded(): CreateResponse {
  return { type: "created" };
}
export function createFailed(error: string): CreateResponse {
  return { type: "failed", error };
}
export interface UploadRequest {
  type: "upload";
  id: string;
  metadata: GDriveUpdateMetadata;
  content: ArrayBuffer;
}
export function gdriveUploadRequest(
  content: ArrayBuffer,
  metadata: GoogleDriveMetadata,
): UploadRequest {
  return {
    type: "upload",
    id: metadata.id,
    metadata: toUpdateable(metadata),
    content,
  };
}
export type UploadResponse =
  | {
      type: "success";
    }
  | {
      type: "failed";
      error: string;
    };
export function uploadSucceeded(): UploadResponse {
  return { type: "success" };
}
export function uploadFailed(error: string): UploadResponse {
  return { type: "failed", error };
}
export interface UpdateMetadataRequest {
  type: "update_metadata";
  id: string;
  metadata: GDriveUpdateMetadata;
}
export function gdriveUpdateRequest(
  id: string,
  metadata: GoogleDriveMetadata,
): UpdateMetadataRequest {
  return {
    type: "update_metadata",
    id,
    metadata: toUpdateable(metadata),
  };
}
export type UpdateMetadataResponse =
  | {
      type: "success";
    }
  | {
      type: "failed";
      error: string;
    };
export function updateSucceeded(): UpdateMetadataResponse {
  return { type: "success" };
}
export function updateFailed(error: string): UpdateMetadataResponse {
  return { type: "failed", error };
}
export type Create = (req: CreateRequest) => Promise<CreateResponse>;
export type Upload = (req: UploadRequest) => Promise<UploadResponse>;
export type UpdateMetadata = (
  req: UpdateMetadataRequest,
) => Promise<UpdateMetadataResponse>;

export function gdriveErrorMessage(body: any): string {
  return body?.error?.message ?? "Unknown error";
}

export type GetAuthToken = () => Promise<string>;
export function gdriveCreate(getToken: GetAuthToken): Create {
  return async function (req: CreateRequest) {
    try {
      const url = "https://www.googleapis.com/drive/v3/files";
      const token = await getToken();
      const resp = await fetch(url, {
        body: JSON.stringify(req.metadata),
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      });
      if (resp.status === 409) {
        return createAlreadyExists();
      } else if (resp.ok) {
        return createSucceeded();
      } else {
        const body = await resp.json();
        return createFailed(gdriveErrorMessage(body));
      }
    } catch (error) {
      return createFailed("Unknown error");
    }
  };
}

export function gdriveGetContent(getToken: GetAuthToken): GetContent {
  return async function (req: GetContentRequest): Promise<GetContentResponse> {
    if (req.contentType.startsWith("application/vnd.google-apps")) {
      return getContentSuccess(new ArrayBuffer(0));
    }
    try {
      const url = `https://www.googleapis.com/drive/v3/files/${req.id}?alt=media`;
      const token = await getToken();
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders(),
          "Content-Type": req.contentType,
          Authorization: `Bearer ${token}`,
        },
      });
      if (resp.ok) {
        const content = await resp.arrayBuffer();
        return getContentSuccess(content);
      } else {
        const body = await resp.json();
        return getContentFailure(gdriveErrorMessage(body));
      }
    } catch {
      return getContentFailure("Unknown error");
    }
  };
}

export function gdriveUpdateMetadata(getToken: GetAuthToken): UpdateMetadata {
  return async function (req: UpdateMetadataRequest) {
    try {
      const url = `https://www.googleapis.com/drive/v3/files/${req.id}`;
      const token = await getToken();
      const resp = await fetch(url, {
        body: JSON.stringify(req.metadata),
        headers: {
          ...defaultHeaders(),
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "PATCH",
      });
      if (resp.ok) {
        return updateSucceeded();
      } else {
        const body = await resp.json();
        return updateFailed(gdriveErrorMessage(body));
      }
    } catch {
      return updateFailed("Unknown error");
    }
  };
}

function getMetadataUrl(id: string): string {
  const fields =
    "id,mimeType,parents,name,kind,trashed,createdTime,modifiedTime,md5Checksum";
  const url = `https://www.googleapis.com/drive/v3/files/${id}?fields=${fields}`;
  return url;
}

export function gdriveGetMetadata(getToken: GetAuthToken): GetMetadata {
  return async function (
    req: GetMetadataRequest,
  ): Promise<GetMetadataResponse> {
    try {
      const url = getMetadataUrl(req.id);
      const token = await getToken();
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders(),
          Authorization: `Bearer ${token}`,
        },
      });
      if (resp.ok) {
        const body = await resp.json();
        return getMetadataSuccess(body);
      } else {
        const body = await resp.json();
        return getMetadataFailure(gdriveErrorMessage(body));
      }
    } catch {
      return getMetadataFailure("Unknown error");
    }
  };
}

function uploadForm(req: UploadRequest): FormData {
  const form = new FormData();
  form.append(
    "metadata",
    new Blob([JSON.stringify(req.metadata)], { type: "application/json" }),
  );
  form.append("file", new Blob([req.content], { type: req.metadata.mimeType }));
  return form;
}

export function gdriveUpload(getToken: GetAuthToken): Upload {
  return async function (req: UploadRequest) {
    try {
      const url = `https://www.googleapis.com/upload/drive/v3/files/${req.id}?uploadType=multipart`;
      const token = await getToken();
      const resp = await fetch(url, {
        method: "PATCH",
        body: uploadForm(req),
        headers: {
          ...defaultHeaders(),
          Authorization: `Bearer ${token}`,
        },
      });
      if (resp.ok) {
        return uploadSucceeded();
      } else {
        const body = await resp.json();
        return uploadFailed(gdriveErrorMessage(body));
      }
    } catch {
      return uploadFailed("Unknown error");
    }
  };
}

export async function fetchIds(getToken: GetAuthToken) {
  const token = await getToken();
  const resp = await fetch(
    "https://www.googleapis.com/drive/v3/files/generateIds?count=100",
    {
      headers: {
        ...defaultHeaders(),
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (!resp.ok) {
    const body = await resp.json();
    const message = gdriveErrorMessage(body);
    throw new Error(message);
  }
  const json = await resp.json();
  const ids = json.ids as string[];
  return ids;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gdriveSearch(getToken: GetAuthToken): Search {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async function (req: SearchRequest): Promise<SearchResponse> {
    try {
      const baseUrl = "https://www.googleapis.com/drive/v3/files";
      const params = {
        fields:
          "nextPageToken, files(id, name, mimeType, modifiedTime, createdTime, trashed)",
        q: req.query,
        pageSize: "1000",
        pageToken: req.pageToken ?? "",
      };
      const encodedParams = new URLSearchParams(params).toString();
      const url = `${baseUrl}?${encodedParams}`;
      const token = await getToken();
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders(),
          Authorization: `Bearer ${token}`,
        },
      });
      if (resp.ok) {
        const json = await resp.json();
        const items = json.files.map((x: any) => ({
          id: x.id,
          contentType: x.mimeType,
        }));
        return searchResponseSuccess(items, json.nextPageToken);
      } else {
        const body = await resp.json();
        return searchResponseFailure(gdriveErrorMessage(body));
      }
    } catch {
      return searchResponseFailure("Unknown error");
    }
  };
}

function defaultHeaders() {
  return {
    "Accept-Encoding": "gzip",
    "User-Agent": "folder.place (gzip)",
  };
}
