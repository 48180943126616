import { PeriodicBackgroundJob } from "./background-jobs";
import { alwaysSuccess, push, PushClient, routingPushClient } from "../push";
import { SourceStore } from "../source-store";
import { gdrivePushClient } from "../google-drive/google-drive-push";
import { SourceType } from "../source-state";
import {
  gdriveCreate,
  gdriveUpdateMetadata,
  gdriveUpload,
} from "../google-drive/google-drive-api";
import { Logger, prefix } from "../logging";
import { GoogleAuth } from "../google-drive/google-drive-auth";
import { AuthManager } from "../auth";
import { AppFileSystem } from "../app-file-system";

export function gdriveClient(auth: GoogleAuth, logger: Logger): PushClient {
  const getToken = async () => {
    const creds = await auth();
    return creds.token;
  };
  // getToken needs to take source id as input
  const create = gdriveCreate(getToken);
  const updateMetadata = gdriveUpdateMetadata(getToken);
  const upload = gdriveUpload(getToken);
  return gdrivePushClient(create, upload, updateMetadata, logger);
}

export function getPushClient(
  auth: AuthManager,
  store: SourceStore,
  logger: Logger,
) {
  return routingPushClient(store, {
    [SourceType.default]: alwaysSuccess,
    [SourceType.gdrive]: gdriveClient(auth.getGoogle(), logger),
  });
}

export const pushJob = (
  auth: AuthManager,
  fs: AppFileSystem,
  logger: Logger,
) => {
  logger = prefix(logger, "[Push]");
  return new PeriodicBackgroundJob({
    name: "push",
    delayMs: 1000,
    job: async () => {
      if (!navigator.onLine) {
        return;
      }
      const sources = await fs.sources.list();
      for (const source of sources) {
        const client = await getPushClient(auth, fs.sources, logger);
        await push(source, fs.files, client);
      }
    },
  });
};
