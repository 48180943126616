import { QueryExecResult, SqlValue } from "sql.js";
import { AppSqlApi } from "../../data/app-sql-api";
import { cluster } from "./clustering";

export interface ObjectListItem {
  readonly id: string;
  readonly title: string;
  readonly text: string;
}

export type ClusteredObjectListItem = ObjectListItem & {
  readonly cluster: string;
};

export interface AttachmentListItem {
  readonly attachmentId: string;
  readonly title: string;
  readonly contentType: string;
  readonly fileId?: string;
  readonly objectId?: string;
  readonly objectTitle?: string;
  readonly createdAt: Date;
}

export interface LinkListItem {
  id: string;
  url: string;
  youTubeId?: string;
  objectId: string;
  objectTitle: string;
}

export interface GroupItem {
  id: string;
  title: string;
  total: number;
}

export interface GroupMember {
  id: string;
  title: string;
  text: string;
  groupId: string;
}

export interface Edge {
  id: string;
  from: string;
  to: string;
}

export class DbQueries {
  constructor(private db: AppSqlApi) {}
  relatedYouTube(id: string) {
    const sql = `
      SELECT link.youTubeId, obj.id, obj.createdAt, obj.modifiedAt FROM
        tags AS tag
        LEFT JOIN links AS link
          ON tag.objectId = link.objectId
        LEFT JOIN objects AS obj
          ON obj.id = tag.objectId
      WHERE
        tag.tagObjectId = ? AND
        link.youTubeId IS NOT NULL
    `;
    const [results] = this.db.queryShared(sql, [id]);
    const ytResults: {
      youTubeId: string;
      id: string;
      createdAt: number;
      modifiedAt: number;
    }[] = [];
    for (const row of results?.values ?? []) {
      const [youTubeId, id, createdAt, modifiedAt] = row;
      ytResults.push({
        youTubeId: youTubeId?.toLocaleString() ?? "",
        id: id?.toLocaleString() ?? "",
        createdAt: createdAt as number,
        modifiedAt: modifiedAt as number,
      });
    }
    return ytResults;
  }
  searchRandomObjects(input: string, limit: number, offset: number) {
    const sql = `
      SELECT obj.id, obj.title, obj.text
      FROM objects AS obj
      WHERE
        (obj.visibility IS NULL OR obj.visibility IS "visible") AND
        ((title LIKE '%' || ? || '%') OR (text LIKE '%' || ? || '%'))
      ORDER BY RANDOM() DESC
    `;
    const params = [input, input];
    return this.objectsQuery({ sql, params }, limit, offset);
  }
  searchOrphanObjects(input: string, limit: number, offset: number) {
    const sql = `
      SELECT obj.id, obj.title, obj.text
      FROM objects AS obj
      WHERE
        (obj.visibility IS NULL OR obj.visibility IS "visible") AND
        ((title LIKE '%' || ? || '%') OR (text LIKE '%' || ? || '%')) AND
        obj.id NOT IN (SELECT tagObjectId FROM tags) AND
        obj.id NOT IN (SELECT objectId FROM tags)
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [input, input];
    return this.objectsQuery({ sql, params }, limit, offset);
  }
  searchStarredObjects(input: string, limit?: number, offset?: number) {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      WHERE
        obj.isStarred = 1 AND
        (obj.visibility IS NULL OR obj.visibility IS "visible") AND
        ((title LIKE '%' || ? || '%') OR (text LIKE '%' || ? || '%'))
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [input, input];
    return this.objectsQuery({ sql, params }, limit, offset);
  }
  searchTrashedObjects(input: string, limit: number, offset: number) {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      WHERE
        obj.visibility IS "trashed" AND
        ((title LIKE '%' || ? || '%') OR (text LIKE '%' || ? || '%'))
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [input, input];
    return this.objectsQuery({ sql, params }, limit, offset);
  }
  schema() {
    const sql = `select sql from sqlite_schema where type = 'table';`;
    const [result] = this.query({ sql });
    const rows = result?.values ?? [];
    const statements = rows.map((x) => x[0]?.toString() ?? "");
    return statements.join("\n\n");
  }
  objectsByTitle(title: string) {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        title = ?
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [title];
    const results = this.objectsQuery({ sql, params });
    return results;
  }
  objectByTitle(title: string) {
    const objects = this.objectsByTitle(title);
    if (objects.length > 1) {
      throw new Error(`Multiple objects with title ${title}`);
    } else if (objects.length === 0) {
      throw new Error(`No object with title ${title}`);
    } else {
      return objects[0];
    }
  }
  objectsWithTags(tagObjectIds: string[]) {
    const qmarks = tagObjectIds.map(() => "?").join(", ");
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      LEFT JOIN tags AS tag ON obj.id = tag.objectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        tag.tagObjectId IN (${qmarks})
    `;
    const params = tagObjectIds;
    return this.objectsQuery({ sql, params });
  }
  objectsWithTag(tagObjectId: string, searchTerms: string) {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      LEFT JOIN tags AS tag ON obj.id = tag.objectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        ((obj.title LIKE '%' || ? || '%') OR (obj.text LIKE '%' || ? || '%')) AND
        tag.tagObjectId = ?
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [searchTerms, searchTerms, tagObjectId];
    return this.objectsQuery({ sql, params });
  }
  tagsOfObjects(objectIds: string[]) {
    const qmarks = objectIds.map(() => "?").join(", ");
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      LEFT JOIN tags AS tag ON obj.id = tag.tagObjectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        tag.objectId IN (${qmarks})
    `;
    const params = objectIds;
    return this.objectsQuery({ sql, params });
  }
  tagsOfObject(objectId: string, searchTerms = "") {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      LEFT JOIN tags AS tag ON obj.id = tag.tagObjectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        ((obj.title LIKE '%' || ? || '%') OR (obj.text LIKE '%' || ? || '%')) AND
        tag.objectId = ?
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [searchTerms, searchTerms, objectId];
    return this.objectsQuery({ sql, params });
  }
  getObject(id: string) {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      WHERE id = ?
    `;
    const params = [id];
    const results = this.objectsQuery({ sql, params });
    return results[0];
  }
  getObjectsWithLink(url: string | undefined): ObjectListItem[] {
    if (!url) {
      return [];
    }
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      LEFT JOIN links AS link ON obj.id = link.objectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        link.url = ?
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [url];
    return this.objectsQuery({ sql, params });
  }
  getObjectsWithIds(ids: string[], includeTrashed = false): ObjectListItem[] {
    const qmarks = ids.map(() => "?").join(", ");
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      WHERE
        (obj.visibility IS NOT 'internal') AND
        ${includeTrashed ? "" : "(obj.visibility IS NOT 'trashed') AND "}
        obj.id IN (${qmarks})
      ORDER BY obj.modifiedAt DESC
    `;
    const params = ids;
    return this.objectsQuery({ sql, params });
  }
  getObjectLinks(id: string): LinkListItem[] {
    const sql = `
      SELECT link.id, link.url, link.youTubeId, link.objectId, obj.title
      FROM
        links AS link
        LEFT JOIN objects AS obj ON obj.id = link.objectId
      WHERE objectId = ?`;
    const [results] = this.query({ sql, params: [id] });
    const rows = results?.values ?? [];
    const links: LinkListItem[] = [];
    const stringify = (x: SqlValue) => x?.toLocaleString() ?? "";
    for (const row of rows) {
      const [id, url, youTubeId, objectId, objectTitle] = row.map(stringify);
      links.push({
        id,
        url,
        youTubeId,
        objectId,
        objectTitle,
      });
    }
    return links;
  }
  getObjects(limit?: number, offset?: number): ObjectListItem[] {
    return this.searchObjects("", limit, offset);
  }
  searchObjects(
    input: string,
    limit?: number,
    offset?: number
  ): ObjectListItem[] {
    const sql = `
      SELECT obj.*
      FROM objects AS obj
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        ((title LIKE '%' || ? || '%') OR (text LIKE '%' || ? || '%'))
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [input, input];
    return this.objectsQuery({ sql, params }, limit, offset);
  }
  searchImages(
    input: string,
    limit?: number,
    offset?: number
  ): AttachmentListItem[] {
    const sql = `
      SELECT
        fileObj.title, obj.title, att.id, att.objectId, att.fileId, att.contentType, att.createdAt
      FROM attachments AS att
      JOIN objects AS obj ON obj.id = att.objectId
      JOIN objects AS fileObj ON fileObj.id = att.fileId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        ((obj.title LIKE '%' || ? || '%') OR (obj.text LIKE '%' || ? || '%')) AND
        att.contentType LIKE 'image/%'
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [input, input];
    return this.attachmentsQuery({ sql, params }, limit, offset);
  }
  relatedImages(id: string, query: string, limit?: number, offset?: number) {
    const sql = `
      SELECT fileObj.title, obj.title, att.*
      FROM attachments AS att
      JOIN objects AS obj ON obj.id = att.objectId
      JOIN objects AS fileObj ON fileObj.id = att.fileId
      JOIN tags AS tag ON obj.id = tag.objectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        ((obj.title LIKE '%' || ? || '%') OR (obj.text LIKE '%' || ? || '%')) AND
        att.contentType LIKE 'image/%' AND
        tag.tagObjectId = ?
      ORDER BY obj.modifiedAt DESC
    `;
    const params = [query, query, id];
    return this.attachmentsQuery({ sql, params }, limit, offset);
  }
  tagCount(id: string) {
    const sql = `
      SELECT COUNT(*)
      FROM objects AS obj
      LEFT JOIN tags AS tag ON obj.id = tag.tagObjectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        tag.objectId = ?
    `;
    const params = [id];
    return this.count({ sql, params });
  }
  relatedCount(id: string) {
    const sql = `
      SELECT COUNT(*)
      FROM objects AS obj
      LEFT JOIN tags AS tag ON obj.id = tag.objectId
      WHERE
        (obj.visibility IS NOT 'internal') AND
        (obj.visibility IS NOT 'trashed') AND
        tag.tagObjectId = ?
    `;
    const params = [id];
    return this.count({ sql, params });
  }
  galleryCount(id: string) {
    const sql = `
      SELECT COUNT(*)
      FROM attachments AS att
      JOIN tags AS tag ON att.objectId = tag.objectId
      WHERE
        att.contentType LIKE 'image/%' AND
        tag.tagObjectId = ?
    `;
    const params = [id];
    return this.count({ sql, params });
  }
  getMainImageId(id: string) {
    const sql = `SELECT img.fileId, min(img.createdAt) FROM attachments AS img WHERE img.objectId = ? AND img.contentType LIKE 'image/%'`;
    const params = [id];
    const [results] = this.query({ sql, params });
    if (!results) {
      return undefined;
    }
    return results.values[0][0]?.toString();
  }
  neighborhood(id: string) {
    const sql = `
    WITH FirstDegree AS (
      SELECT DISTINCT tagObjectId as neighborId 
      FROM tags
      WHERE objectId = ? AND tagObjectId <> ?
      UNION
      SELECT DISTINCT objectId as neighborId 
      FROM tags
      WHERE tagObjectId = ? AND objectId <> ?
  ),
  SecondDegree AS (
      SELECT DISTINCT t1.tagObjectId as neighborId 
      FROM tags t1
      JOIN FirstDegree f1 ON t1.objectId = f1.neighborId
      WHERE t1.tagObjectId <> ?
      UNION
      SELECT DISTINCT t1.objectId as neighborId 
      FROM tags t1
      JOIN FirstDegree f1 ON t1.tagObjectId = f1.neighborId
      WHERE t1.objectId <> ?
  ),
  CountConnections AS (
      SELECT s.neighborId, COUNT(DISTINCT fd.neighborId) as connections
      FROM SecondDegree s
      LEFT JOIN tags t ON (s.neighborId = t.objectId OR s.neighborId = t.tagObjectId)
      JOIN FirstDegree fd ON (t.objectId = fd.neighborId OR t.tagObjectId = fd.neighborId)
      WHERE t.objectId <> ? AND t.tagObjectId <> ?
      GROUP BY s.neighborId
      HAVING connections >= 2
  )
  SELECT o.*
  FROM objects o
  WHERE o.id IN (SELECT neighborId FROM FirstDegree)
  OR o.id IN (SELECT neighborId FROM CountConnections)
    `;
    const params = [id, id, id, id, id, id, id, id];
    return this.objectsQuery({ sql, params });
  }
  networkView(id: string) {
    const concat = this.neighborhood(id);
    const ids = [...new Set(concat.map((x) => x.id))];
    const qmarks = ids.map(() => "?").join(", ");
    const query = `
      SELECT id, objectId, tagObjectId FROM tags WHERE objectId IN (${qmarks}) AND tagObjectId IN (${qmarks})
    `;
    const [results] = this.query({ sql: query, params: [...ids, ...ids] });
    const edges: Record<string, Edge> = {};
    for (const row of results?.values ?? []) {
      const edge: Edge = {
        id: row[0]?.toString() ?? "",
        from: row[1]?.toString() ?? "",
        to: row[2]?.toString() ?? "",
      };
      if (edge.from === id || edge.to === id) {
        continue;
      }
      const [a, b] = [edge.from, edge.to].sort();
      edge.id = `${a}-${b}`;
      edges[edge.id] = edge;
    }
    const finalEdges = Object.values(edges);
    const dict: Record<string, ObjectListItem> = {};
    for (const obj of concat) {
      dict[obj.id] = obj;
    }
    const finalObjects = Object.values(dict);
    const members = cluster({ nodes: finalObjects, edges: finalEdges });
    const withCluster: ClusteredObjectListItem[] = [];
    for (const object of finalObjects) {
      withCluster.push({
        ...object,
        cluster: members[object.id]?.toString() ?? "",
      });
    }
    return { edges: finalEdges, objects: withCluster };
  }
  objectNeighborhood(id: string) {
    const nbrSql = `
    WITH neighbors
          AS (SELECT id
              FROM   (SELECT tagObjectId AS id
                      FROM   tags
                      WHERE  objectId = ?
                      UNION
                      SELECT objectId AS id
                      FROM   tags
                      WHERE  tagObjectId = ?)
              WHERE  id IS NOT NULL
                      AND id <> '')
    `;
    const groupsSql = `
      SELECT * FROM (SELECT obj.title AS title,
          tag.tagObjectId AS id,
          Count(*) AS total
      FROM   tags AS tag
          INNER JOIN neighbors AS nbr
                  ON tag.objectId = nbr.id
          INNER JOIN objects AS obj
                  ON tag.tagObjectId = obj.id
      WHERE  tag.tagObjectId != ?
      GROUP  BY tag.tagObjectId
      ORDER  BY total DESC) WHERE total > 1;
    `;
    const [results1] = this.query({
      sql: `${nbrSql}\n${groupsSql}`,
      params: [id, id, id],
    });
    const groups: GroupItem[] = [];
    for (const row of results1?.values ?? []) {
      const groupItem: GroupItem = {
        title: row[0]?.toLocaleString() ?? "",
        id: row[1]?.toLocaleString() ?? "",
        total: parseInt(row[2]?.toLocaleString() ?? ""),
      };
      groups.push(groupItem);
    }
    const groupIds = groups.map((x) => x.id);
    const qmarks = groupIds.map(() => "?").join(", ");
    const memberSql = `
      SELECT DISTINCT
        obj.id AS id,
        obj.title AS title,
        obj.text AS text,
        groupObj.id AS groupId
      FROM
        neighbors AS nbr
        INNER JOIN tags AS tag
          ON tag.objectId = nbr.id
        INNER JOIN objects AS groupObj
          ON tag.tagObjectId = groupObj.id
        INNER JOIN objects AS obj
          ON obj.id = nbr.id
      WHERE
        groupObj.id IN (${qmarks})
    `;
    const [results2] = this.query({
      sql: `${nbrSql}\n${memberSql}`,
      params: [id, id, ...groupIds],
    });
    const members: GroupMember[] = [];
    for (const row of results2?.values ?? []) {
      const member: GroupMember = {
        id: row[0]?.toLocaleString() ?? "",
        title: row[1]?.toLocaleString() ?? "",
        text: row[2]?.toLocaleString() ?? "",
        groupId: row[3]?.toLocaleString() ?? "",
      };
      members.push(member);
    }
    return { groups, members };
  }
  private objectsQuery(query: Query, limit?: number, offset?: number) {
    const limited = withLimitClause(query, limit, offset);
    const results = this.query(limited);
    return this.toObjectListItems(results);
  }
  private attachmentsQuery(query: Query, limit?: number, offset?: number) {
    const limited = withLimitClause(query, limit, offset);
    const results = this.query(limited);
    return this.toAttachmentListItems(results);
  }
  private query(query: Query) {
    return this.db.queryShared(query.sql, query.params);
  }
  private count(query: Query) {
    const [results] = this.query(query);
    if (!results) {
      return 0;
    } else {
      const count = results.values[0][0];
      if (typeof count === "number") {
        return count;
      } else {
        return 0;
      }
    }
  }
  private toObjectListItems(results: QueryExecResult[]): ObjectListItem[] {
    const [result] = results;
    if (!result) {
      return [];
    }
    const items: ObjectListItem[] = [];
    for (const row of result.values) {
      const id = row[0]?.toLocaleString() ?? "";
      const title = row[1]?.toLocaleString() ?? "";
      const text = row[2]?.toLocaleString() ?? "";
      items.push({
        id,
        title,
        text,
      });
    }
    return items;
  }
  toAttachmentListItems(results: QueryExecResult[]): AttachmentListItem[] {
    const [result] = results;
    if (!result) {
      return [];
    }
    const items: AttachmentListItem[] = [];
    for (const row of result.values) {
      const [
        fileObjTitle,
        objTitle,
        attId,
        attObjectId,
        attFileId,
        attContentType,
        attCreatedAt,
      ] = row;
      items.push({
        attachmentId: attId?.toLocaleString() ?? "",
        title: fileObjTitle?.toLocaleString() ?? "Untitled Attachment",
        contentType:
          attContentType?.toLocaleString() ?? "application/octet-stream",
        fileId: attFileId?.toLocaleString() ?? "",
        objectId: attObjectId?.toLocaleString() ?? "",
        objectTitle: objTitle?.toLocaleString() ?? "",
        createdAt: new Date(
          typeof attCreatedAt === "number"
            ? attCreatedAt
            : parseInt(attCreatedAt?.toLocaleString() ?? "") ?? 0
        ),
      });
    }
    return items;
  }
}

interface Query {
  sql: string;
  params?: SqlValue[] | undefined;
}

function withLimitClause(query: Query, limit?: number, offset?: number) {
  if (limit !== undefined && offset !== undefined) {
    const sql = `${query.sql}\nLIMIT ? OFFSET ?`;
    const params = [...(query.params ?? []), limit, offset];
    return { sql, params };
  } else if (limit !== undefined) {
    const sql = `${query.sql}\nLIMIT ?`;
    const params = [...(query.params ?? []), limit];
    return { sql, params };
  } else {
    return query;
  }
}
