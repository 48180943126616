import {
  compose,
  FileData,
  pullFailed,
  pullSucceeded,
  remoteUpsert,
} from "./file-state";
import { FileStore } from "./file-store";

export interface PullRequest {
  id: string;
  revision: number;
  data?: FileData;
}

export interface BatchPullRequest {
  source: string;
  requests: PullRequest[];
}

export type PullResponse =
  | {
      id: string;
      result: "success";
      data: FileData;
    }
  | {
      id: string;
      result: "failure";
      error: string;
    };
export function pullSucceededResponse(
  id: string,
  data: FileData,
): PullResponse {
  return { id, data, result: "success" };
}
export function pullFailedResponse(id: string, error: string): PullResponse {
  return { id, error, result: "failure" };
}

export interface BatchPullResponse {
  source: string;
  responses: PullResponse[];
}

export type PullClient = (req: BatchPullRequest) => Promise<BatchPullResponse>;

export function alwaysSuccess(data: FileData): PullClient {
  return async function (req: BatchPullRequest): Promise<BatchPullResponse> {
    const resps: PullResponse[] = req.requests.map((r) => ({
      id: r.id,
      result: "success",
      data: data,
      contentChanged: true,
      requestData: data,
    }));
    return { source: req.source, responses: resps };
  };
}

async function getPullRequest(
  source: string,
  store: FileStore,
  limit: number,
): Promise<BatchPullRequest> {
  const data = await store.listNeedsPull(source, limit);
  const requests: PullRequest[] = [];
  for (const [id, state] of Object.entries(data)) {
    const request: PullRequest = {
      id,
      data: state.data,
      revision: state.revision,
    };
    requests.push(request);
  }
  return { requests, source };
}

function pullResponseToAction(source: string, resp: PullResponse, now: Date) {
  if (resp.result === "failure") {
    return pullFailed(resp.error);
  }
  const { data } = resp;
  return compose(remoteUpsert(source, data, now), pullSucceeded());
}

export async function pull(
  source: string,
  fileStore: FileStore,
  client: PullClient,
  limit: number,
  timestamp?: Date,
): Promise<void> {
  const request = await getPullRequest(source, fileStore, limit);
  if (!request.requests.length) {
    return;
  }
  const responses = await client(request);
  for (const resp of responses.responses) {
    const action = pullResponseToAction(
      request.source,
      resp,
      timestamp ?? new Date(),
    );
    await fileStore.apply(resp.id, action);
  }
}
