import { AppDb } from "../app-db";
import { AppFileSystem } from "../app-file-system";
import { DbManager } from "../app-sql-api";
import { AuthManager } from "../auth";
import { DbGDriveCrawlerStore } from "../google-drive/google-drive-crawler-store";
import { DbLogStore } from "../log-store";
import { Logger } from "../logging";
import { DbSourceStore } from "../source-store";
import { shortId } from "../uuid";
import { googleChangeWatcherJob } from "./google-drive-change-watcher-job";
import { gdriveCrawlJob } from "./google-drive-crawl-job";
import { logCleanupJob } from "./log-cleanup-job";
import { pullJob } from "./pull-job";
import { pushJob } from "./push-job";
import { snapshotDbJob } from "./snapshot-db-job";

export const windowId = shortId();

function neverResolve() {
  return new Promise(() => {
    return;
  });
}

let jobsRunning = false;
function tryRunJobsOnce(jobs: PeriodicBackgroundJob[]) {
  if (jobsRunning) {
    return;
  }
  jobsRunning = true;
  for (const job of jobs) {
    job.tryRun(undefined);
  }
  jobsRunning = false;
}

export type JobContext = undefined;

interface BackgroundJobConfig {
  name: string;
  job: (context: JobContext) => Promise<any>;
  delayMs: number;
}

async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class PeriodicBackgroundJob {
  private job: (context: JobContext) => Promise<any>;
  private delayMs: number;
  private isRunning = false;
  readonly name: string;
  constructor({ name, job, delayMs }: BackgroundJobConfig) {
    this.job = job;
    this.delayMs = delayMs;
    this.name = name;
  }
  tryRun(context: JobContext) {
    if (this.isRunning) {
      return false;
    }
    this.run(context);
    return true;
  }
  private async run(context: JobContext) {
    this.isRunning = true;
    try {
      await this.job(context);
    } finally {
      await delay(this.delayMs);
      this.isRunning = false;
    }
  }
}

function initializeBackgroundJobs(
  db: AppDb,
  auth: AuthManager,
  fs: AppFileSystem,
  sqlDbs: DbManager,
  logger: Logger,
): PeriodicBackgroundJob[] {
  const crawlerStore = new DbGDriveCrawlerStore(db);
  const logStore = new DbLogStore(db);
  const sourceStore = new DbSourceStore(db);
  return [
    pullJob(auth, fs, logger),
    pushJob(auth, fs, logger),
    googleChangeWatcherJob(auth.getGoogle(), fs, db, logger),
    gdriveCrawlJob(auth.getGoogle(), fs, crawlerStore, logger),
    logCleanupJob(logStore),
    snapshotDbJob(sourceStore, sqlDbs, logger),
  ];
}

function isVisible() {
  return window.document.visibilityState === "visible";
}
const activeWindowChannel = new BroadcastChannel("active-window");
// Broadcast this window's visibility state to the other windows
window.document.addEventListener("visibilitychange", () => {
  activeWindowChannel.postMessage({
    id: windowId,
    visible: isVisible(),
  });
});
// Send out an initial broadcast
activeWindowChannel.postMessage({
  id: windowId,
  visible: isVisible(),
});
// Keep track of other windows' visibility state
const windowVisibilityState: { [windowId: string]: boolean } = {};
activeWindowChannel.addEventListener("message", (event) => {
  const data = event.data;
  if (
    !data ||
    typeof data.id !== "string" ||
    typeof data.visible !== "boolean"
  ) {
    return;
  }
  windowVisibilityState[data.id] = data.visible;
});

export class BackgroundJobRunner {
  private interval: NodeJS.Timer | undefined;
  constructor(private jobs: PeriodicBackgroundJob[]) {}
  private start() {
    if (!this.interval) {
      this.interval = setInterval(() => tryRunJobsOnce(this.jobs), 1000);
    }
  }
  private stop() {
    if (this.interval) {
      // not sure why i need to typecast
      clearInterval(this.interval as unknown as number);
      this.interval = undefined;
    }
  }
  update() {
    if (window.document.visibilityState === "visible") {
      this.start();
    } else if (Object.values(windowVisibilityState).some((x) => x)) {
      this.start();
    } else {
      this.stop();
    }
  }
}

export function initializeBackgroundJobRunner(
  leaderLockName: string,
  db: AppDb,
  auth: AuthManager,
  fs: AppFileSystem,
  sqlDbs: DbManager,
  logger: Logger,
) {
  const jobs = initializeBackgroundJobs(db, auth, fs, sqlDbs, logger);
  // Code in this block will run in exactly one browser window at a time
  // When one window closes, another one will grab the lock (if it exists)
  navigator.locks.request(leaderLockName, async () => {
    logger(`${windowId} is now running background jobs`);
    const runner = new BackgroundJobRunner(jobs);
    // When this window changes visibility, or another one does, update the
    // state of the background job runner.
    window.addEventListener("visibilitychange", () => runner.update());
    activeWindowChannel.addEventListener("message", () => runner.update());
    runner.update();
    return neverResolve();
  });
}
