import { PrivateTableVersionDb } from "../../data/app-db";

export interface Migration {
  upgrade(tag: string, minVersion: number, task: () => void): Promise<void>;
  needsBackfill(): boolean;
}

export class DummyMigration implements Migration {
  async upgrade(
    tag: string,
    minVersion: number,
    task: () => void,
  ): Promise<void> {
    task();
  }
  needsBackfill(): boolean {
    return true;
  }
}

export class PrivateTableMigration implements Migration {
  private backfill = false;
  constructor(private db: PrivateTableVersionDb) {}
  async upgrade(tag: string, minVersion: number, task: () => void) {
    const record = await this.db.privateTableVersions.get(tag);
    if (record && record?.version >= minVersion) {
      return;
    }
    task();
    await this.db.privateTableVersions.put({ tag, version: minVersion });
    this.backfill = true;
  }
  needsBackfill() {
    return this.backfill;
  }
}
