import React from "react";
import { Link } from "react-router-dom";
import { CellImage } from "../CellImage";
import { useDbQueries } from "../notes-hooks";

export function ImageListView({ id }: { id: string }) {
  const queries = useDbQueries();
  const images = queries.relatedImages(id, "");
  return (
    <>
      {images.map(
        (x, i) =>
          x.fileId && (
            <Link key={i} to={`/notes/note/${x.objectId}`}>
              <CellImage
                thumbnailSize={100}
                id={x.fileId}
                width="100%"
                height=""
              />
            </Link>
          ),
      )}
    </>
  );
}
