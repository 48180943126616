import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { FpApi } from "./fp-api";

function shuffleArray<T>(array: T[]) {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}

export function PlaylistAction({ api, id }: { api: FpApi; id: string }) {
  const youtubes = api.relatedYouTube(id);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const shuffled = sortBy(youtubes, () => Math.random()).map(x => x.youTubeId).join(",");
  const recentlyAdded = sortBy(youtubes, (youtube) => -youtube.createdAt).map(x => x.youTubeId).join(",");
  const makeUrl = (idList: string) => `https://www.youtube.com/watch_videos?video_ids=${idList}`;
  if (youtubes.length === 0) {
    return null;
  }
  return (
    <div>
      <Button
        variant="outlined"
        endIcon={<KeyboardArrowDown />}
        size="small"
        component="a"
        target="_blank"
        onClick={handleClick}
      >
        Playlist
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          component="a"
          target="_blank"
          href={makeUrl(shuffled)}
          onClick={handleClose}
        >
          Random
        </MenuItem>
        <MenuItem
          disableRipple
          component="a"
          target="_blank"
          href={makeUrl(recentlyAdded)}
          onClick={handleClose}
        >
          New
        </MenuItem>
      </Menu>
    </div>
  );
}

function sortBy<T>(array: T[], key: (item: T) => number) {
  return [...array].sort((a, b) => key(a) - key(b));
}