import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { OptionsOrDependencyArray } from "react-hotkeys-hook/dist/types";
import { useNavigate } from "react-router-dom";
import { getController } from "../../data/controller";
import { shortId } from "../../data/uuid";
import { useDbSubscription, useSourceId } from "../hooks";
import { extractUrls } from "./db-links";
import { DbQueries } from "./db-queries";

export function useFpApi(subscribe = true) {
  const sourceId = useSourceId();
  const controller = getController();
  useDbSubscription(subscribe);
  return controller.fpApis.get(sourceId);
}

export function useDbQueries(subscribe = true) {
  const sourceId = useSourceId();
  const controller = getController();
  const db = controller.sqlDbs.getDb(sourceId);
  const [queries, setQueries] = useState(new DbQueries(db));
  useEffect(() => {
    const sub = db
      .allChangeEvents()
      .subscribe(() => subscribe && setQueries(new DbQueries(db)));
    return () => sub.unsubscribe();
  }, [db, subscribe]);
  return queries;
}

export function useObjectByTitle(title: string, upsert = false) {
  const queries = useDbQueries();
  const api = useFpApi();
  try {
    if (upsert) {
      const objs = queries.objectsByTitle(title);
      if (objs.length === 0) {
        api.addNote(title, "");
      }
    }
    return { object: queries.objectByTitle(title), error: undefined };
  } catch (e: any) {
    return { object: undefined, error: e.message };
  }
}

export function useAddNote() {
  const api = useFpApi();
  return useCallback(
    (titleValue: string, contentValue?: string) => {
      const title = titleValue || "New Note";
      const urls = extractUrls(title);
      if (urls.length === 1 && title.endsWith(urls[0])) {
        const url = urls[0];
        const newTitle = title.substring(0, title.length - url.length).trim();
        const content = contentValue ? `${contentValue}\n\n${url}` : url;
        const id = api.addNote(newTitle, content);
        return id;
      } else {
        const id = api.addNote(title, contentValue ?? "");
        return id;
      }
    },
    [api],
  );
}

export function useObjectAsSetting(title: string, upsert = false) {
  const { object, error } = useObjectByTitle(title, upsert);
  if (error) {
    return { value: undefined, id: undefined, error };
  } else {
    return { value: object?.text, id: object?.id, error: undefined };
  }
}

function toFileArray(files: File[] | FileList): File[] {
  let arr: File[];
  if (Array.isArray(files)) {
    arr = files;
  } else {
    arr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        arr.push(file);
      }
    }
  }
  return arr;
}

export function usePasteFiles(onPaste: (files: File[]) => void) {
  useEffect(() => {
    const cb = (e: ClipboardEvent) =>
      onPaste(toFileArray(e.clipboardData?.files ?? []));
    window.document.addEventListener("paste", cb);
    return () => window.document.removeEventListener("paste", cb);
  }, [onPaste]);
}

export interface EditorStateProps<T> {
  getObjects: (props: {
    input: string;
    page: number;
    queries: DbQueries;
  }) => T[];
  getSelectedObjects: (props: { input: string; queries: DbQueries }) => T[];
  getObjectId: (object: T) => string;
  toggle: (object: T) => void;
  add?: (props: { input: string; queries: DbQueries }) => T;
}

export interface EditorState<T> {
  readonly input: string;
  readonly setInput: (value: string) => void;
  readonly setEditMode: () => void;
  readonly setViewMode: () => void;
  readonly items: Array<Item<T>>;
  readonly toggleObject: (object: T) => void;
  readonly addObject: () => void;
  readonly isEditing: boolean;
  readonly selection: SelectionState;
  readonly loadNextPage: () => void;
  readonly inputRef: RefObject<HTMLInputElement>;
}

export interface SelectionState {
  index: number | undefined;
  moveUp: () => void;
  moveDown: () => void;
  clear: () => void;
}

export interface Item<T> {
  object: T;
  selected: boolean;
}

function makeItem<T>(object: T, selected: boolean): Item<T> {
  return { object, selected };
}

export function useEditModeState<T>(
  props: EditorStateProps<T>,
  queries: DbQueries,
) {
  const { getSelectedObjects, getObjects, getObjectId } = props;
  const [pinnedIds, setPinnedIds] = useState<string[]>([]);
  const [input, setInputBase] = useState("");
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<Item<T>[]>([]);
  const setInput = useCallback(
    (input: string, usePinnedIds?: string[] | undefined) => {
      const trimmed = input.trim();
      setInputBase(input);
      setPage(0);
      const selectedObjects = getSelectedObjects({ input: trimmed, queries });
      const restObjects = getObjects({ input: trimmed, page: 0, queries });
      const visibleLookup = Object.fromEntries(
        [...selectedObjects, ...restObjects].map((x) => [getObjectId(x), x]),
      );
      const selectedIds = new Set(selectedObjects.map(getObjectId));
      const top: Item<T>[] = [];
      for (const id of usePinnedIds ?? pinnedIds) {
        const object = visibleLookup[id];
        if (object) {
          const selected = selectedIds.has(id);
          const item = makeItem(object, selected);
          top.push(item);
        }
      }
      const topIds = new Set(top.map((x) => getObjectId(x.object)));
      const bottom: Item<T>[] = [];
      for (const object of restObjects) {
        const id = getObjectId(object);
        if (!topIds.has(id)) {
          const selected = selectedIds.has(id);
          const item = makeItem(object, selected);
          bottom.push(item);
        }
      }
      setItems([...top, ...bottom]);
    },
    [queries, getObjectId, getObjects, getSelectedObjects, pinnedIds],
  );
  const initialize = useCallback(
    (pinned: string[]) => {
      setPinnedIds(pinned);
      setInput("", pinned);
    },
    [setInput],
  );
  const loadNextPage = useCallback(() => {
    const trimmed = input.trim();
    setPage(page + 1);
    const objects = getObjects({ input: trimmed, page: page + 1, queries });
    const selectedObjects = getSelectedObjects({ input: trimmed, queries });
    const selectedIds = new Set(selectedObjects.map(getObjectId));
    const existing = new Set(items.map((x) => getObjectId(x.object)));
    const newItems: Item<T>[] = [];
    for (const object of objects) {
      const id = getObjectId(object);
      if (!existing.has(id)) {
        const selected = selectedIds.has(id);
        const item = makeItem(object, selected);
        newItems.push(item);
      }
    }
    setItems((prev) => [...prev, ...newItems]);
  }, [
    queries,
    getObjectId,
    getObjects,
    getSelectedObjects,
    input,
    items,
    page,
  ]);
  const toggleEffect = props.toggle;
  const toggle = useCallback(
    (object: T) => {
      const id = getObjectId(object);
      setItems((prev) =>
        prev.map((item) => {
          if (getObjectId(item.object) === id) {
            return makeItem(object, !item.selected);
          } else {
            return item;
          }
        }),
      );
      toggleEffect(object);
    },
    [getObjectId, toggleEffect],
  );
  const addEffect = props.add;
  const add = useCallback(() => {
    if (!addEffect) {
      return;
    }
    const object = addEffect({ input, queries });
    const selected = true;
    const item = makeItem(object, selected);
    setItems((prev) => [item, ...prev]);
    setInput("");
  }, [queries, addEffect, input, setInput]);
  return { input, setInput, initialize, items, loadNextPage, toggle, add };
}

export function useViewModeState<T>(
  props: EditorStateProps<T>,
  queries: DbQueries,
) {
  const { getSelectedObjects } = props;
  const [items, setItems] = useState<Item<T>[]>([]);
  const initialize = useCallback(() => {
    const items = getSelectedObjects({ input: "", queries }).map((x) =>
      makeItem(x, true),
    );
    setItems(items);
  }, [queries, getSelectedObjects]);
  useEffect(initialize, [initialize]);
  return { items, initialize };
}

export function useSelectionState<T>(items: T[]): SelectionState {
  const [index, setIndex] = useState<number | undefined>(undefined);
  const moveUp = useCallback(
    () => setIndex((current) => (current ? current - 1 : undefined)),
    [],
  );
  const moveDown = useCallback(
    () =>
      setIndex((current) => {
        if (current === undefined) {
          return 0;
        } else if (current < items.length - 1) {
          return current + 1;
        } else {
          return current;
        }
      }),
    [items],
  );
  const clear = useCallback(() => setIndex(undefined), []);
  return useMemo(
    () => ({
      index,
      moveUp,
      moveDown,
      clear,
    }),
    [index, moveUp, moveDown, clear],
  );
}
export function useEditorState<T>(props: EditorStateProps<T>): EditorState<T> {
  const [isEditing, setIsEditing] = useState(false);
  const queries = useDbQueries(!isEditing);
  const viewModeState = useViewModeState(props, queries);
  const editModeState = useEditModeState(props, queries);
  const initializeEdit = editModeState.initialize;
  const initializeView = viewModeState.initialize;
  const items = isEditing ? editModeState.items : viewModeState.items;
  const selection = useSelectionState(items);
  const setInputBase = editModeState.setInput;
  const inputRef = useRef<HTMLInputElement>(null);
  const setInput = useCallback(
    (input: string) => {
      setInputBase(input);
      selection.clear();
    },
    [setInputBase, selection],
  );
  const viewItems = viewModeState.items;
  const { getObjectId } = props;
  const setEditMode = useCallback(() => {
    if (isEditing) {
      return;
    }
    setIsEditing(true);
    initializeEdit(viewItems.map((x) => getObjectId(x.object)));
  }, [isEditing, initializeEdit, viewItems, getObjectId]);
  const setViewMode = useCallback(() => {
    if (!isEditing) {
      return;
    }
    setInput("");
    setIsEditing(false);
    initializeView();
  }, [initializeView, isEditing, setInput]);
  return {
    input: editModeState.input,
    setInput,
    setEditMode,
    setViewMode,
    items,
    isEditing,
    toggleObject: editModeState.toggle,
    addObject: editModeState.add,
    selection,
    loadNextPage: editModeState.loadNextPage,
    inputRef,
  };
}

export function useObjectNavigate() {
  const navigate = useNavigate();
  return useCallback(
    (id: string, tab?: string) => {
      const url = tab ? `/notes/note/${id}#${tab}` : `/notes/note/${id}`;
      navigate(url);
    },
    [navigate],
  );
}

class ArrowKeyStack {
  constructor(public stack: string[] = []) {}
  push(id: string) {
    this.stack.unshift(id);
  }
  remove(id: string) {
    this.stack = this.stack.filter((x) => x !== id);
  }
  isTop(id: string) {
    return this.stack[0] === id;
  }
}
const arrowKeyStack = new ArrowKeyStack();
export function useArrowKeyStack() {
  // This makes it so only one calling component can use the arrow keys at a time
  // Each caller gets an id
  // Maintain a stack of ids, and only allow the top of the stack to be active
  const id = useMemo(() => shortId(), []);
  useEffect(() => {
    arrowKeyStack.push(id);
    return () => arrowKeyStack.remove(id);
  }, [id]);
  const isActive = useCallback(() => arrowKeyStack.isTop(id), [id]);
  return { isActive };
}
export interface ArrowKeyNavProps<T> {
  selection: SelectionState;
  vimMode?: boolean;
  onSelect: (object: T) => void;
  objects: T[];
}
export function useArrowKeyNav<T>({
  onSelect,
  selection,
  vimMode,
  objects,
}: ArrowKeyNavProps<T>) {
  const { isActive } = useArrowKeyStack();
  const opts: OptionsOrDependencyArray = { enableOnFormTags: ["input"] };
  const deps = [selection, vimMode, objects];
  const downKeys = vimMode ? "j, down" : "down";
  useHotkeys(
    downKeys,
    (e) => {
      if (isActive()) {
        selection.moveDown();
        e.preventDefault();
      }
    },
    opts,
    deps,
  );
  const upkeys = vimMode ? "k, up" : "up";
  useHotkeys(
    upkeys,
    (e) => {
      if (isActive()) {
        selection.moveUp();
        e.preventDefault();
      }
    },
    opts,
    deps,
  );
  useHotkeys(
    "enter",
    () => {
      if (!isActive()) {
        return;
      }
      const selectedIndex = selection.index;
      if (selectedIndex !== undefined && objects[selectedIndex]) {
        const object = objects[selectedIndex];
        onSelect(object);
      }
    },
    opts,
    deps,
  );
}
