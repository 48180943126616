import * as SparkMD5 from "spark-md5";

export type FileData = {
  readonly metadata: FileMetadata;
  readonly content: ArrayBuffer;
};
export interface FileMetadata {
  readonly name: string;
  readonly isDeleted: boolean;
  readonly contentType: string;
  readonly createdAt: number;
  readonly modifiedAt: number;
  readonly parent?: string;
  readonly md5Checksum: string;
}
export interface PushState {
  readonly contentModified: boolean;
  readonly metadataModified: boolean;
  readonly requestedTimestamp: Date;
  readonly error?: string;
}
export interface PullState {
  readonly needsPull: boolean;
  readonly requestedTimestamp: Date;
  readonly error?: string;
}
export interface CrawlState {
  readonly needsCrawl: boolean;
}
export interface FileState {
  readonly source: string;
  readonly revision: number;
  readonly remoteExists: boolean;
  readonly push: PushState;
  readonly pull: PullState;
  readonly crawl: CrawlState;
  readonly data?: FileData;
}

export type FileAction = (state?: FileState) => FileState;

export function localAdd(
  source: string,
  data: FileData,
  changeTimestamp?: Date,
): FileAction {
  return function (state?: FileState) {
    if (state) {
      throw new Error("File state already exists");
    }
    return {
      source,
      revision: 0,
      remoteExists: false,
      push: {
        contentModified: true,
        metadataModified: true,
        requestedTimestamp: changeTimestamp ?? new Date(),
      },
      pull: {
        needsPull: false,
        requestedTimestamp: changeTimestamp ?? new Date(),
      },
      crawl: {
        needsCrawl: false,
      },
      data,
    };
  };
}

export function needsPush(state?: FileState): boolean {
  if (!state) {
    return false;
  }
  return state.push.contentModified || state.push.metadataModified;
}

function contentEqual(before: FileData | undefined, after: FileData) {
  if (!before) {
    return false;
  }
  return md5(before.content) === md5(after.content);
}

function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }
  function isObject(obj: any) {
    if (typeof obj === "object" && obj !== null) {
      return true;
    } else {
      return false;
    }
  }
}

function metadataEqual(before: FileData | undefined, after: FileData) {
  if (!before) {
    return false;
  }
  return deepEqual(before.metadata, after.metadata);
}

export function localUpdate(
  data: FileData,
  changeTimestamp?: Date,
): FileAction {
  return function (state?: FileState) {
    if (!state) {
      throw new Error("File state does not exist");
    }
    const requestedTimestamp = needsPush(state)
      ? state.push.requestedTimestamp
      : changeTimestamp;
    const contentModified = !contentEqual(state.data, data);
    const metadataModified = !metadataEqual(state.data, data);
    return {
      ...state,
      revision: state.revision + 1,
      push: {
        contentModified,
        metadataModified,
        requestedTimestamp: requestedTimestamp ?? new Date(),
      },
      pull: {
        ...state.pull,
        needsPull: false,
      },
      data,
    };
  };
}

export function pushSucceeded(pushedRevision: number): FileAction {
  return function (state?: FileState): FileState {
    if (!state) {
      throw new Error("File state does not exist");
    }
    if (state.revision !== pushedRevision) {
      return {
        ...state,
        push: {
          ...state.push,
          requestedTimestamp: state.push.requestedTimestamp,
          error: undefined,
        },
        remoteExists: true,
      };
    }
    return {
      ...state,
      push: {
        contentModified: false,
        metadataModified: false,
        requestedTimestamp: state.push.requestedTimestamp,
        error: undefined,
      },
      remoteExists: true,
    };
  };
}

export function pushFailed(pushedRevision: number, error: string): FileAction {
  return function (state?: FileState): FileState {
    if (!state) {
      throw new Error("File state does not exist");
    }
    if (state.revision !== pushedRevision) {
      return { ...state };
    }
    return {
      ...state,
      push: {
        ...state.push,
        error: error,
      },
    };
  };
}

export function remoteUpsert(
  source: string,
  data: FileData,
  changeTimestamp?: Date,
): FileAction {
  return function (state?: FileState): FileState {
    if (state && needsPush(state)) {
      return { ...state, remoteExists: true };
    }
    const currentRevision = state?.revision ?? -1;
    return {
      source,
      revision: currentRevision + 1,
      data,
      remoteExists: true,
      push: {
        contentModified: false,
        metadataModified: false,
        requestedTimestamp: changeTimestamp ?? new Date(),
      },
      pull: state?.pull ?? {
        needsPull: false,
        requestedTimestamp: changeTimestamp ?? new Date(),
      },
      crawl: state?.crawl ?? {
        needsCrawl: false,
      },
    };
  };
}

export function requestCrawl(source: string, timestamp?: Date): FileAction {
  return function (state?: FileState): FileState {
    const now = new Date();
    if (!state) {
      return {
        source,
        revision: 0,
        remoteExists: true,
        push: {
          contentModified: false,
          metadataModified: false,
          requestedTimestamp: timestamp ?? now,
        },
        pull: {
          needsPull: true,
          requestedTimestamp: timestamp ?? now,
        },
        crawl: {
          needsCrawl: true,
        },
      };
    }
    return {
      ...state,
      crawl: {
        needsCrawl: true,
      },
    };
  };
}

export function markVisited(): FileAction {
  return function (state?: FileState): FileState {
    if (!state) {
      throw new Error("not found");
    }
    return {
      ...state,
      crawl: {
        needsCrawl: false,
      },
    };
  };
}

export function requestPull(source: string, timestamp?: Date): FileAction {
  return function (state?: FileState): FileState {
    const now = new Date();
    if (!state) {
      return {
        source,
        revision: 0,
        remoteExists: true,
        push: {
          contentModified: false,
          metadataModified: false,
          requestedTimestamp: timestamp ?? now,
        },
        pull: {
          needsPull: true,
          requestedTimestamp: timestamp ?? now,
        },
        crawl: {
          needsCrawl: false,
        },
      };
    }
    if (needsPush(state)) {
      return { ...state };
    }
    return {
      ...state,
      pull: {
        needsPull: true,
        requestedTimestamp: timestamp ?? new Date(),
      },
    };
  };
}

export function pullSucceeded(): FileAction {
  return function (state?: FileState): FileState {
    if (!state) {
      throw new Error("File not found");
    }
    return {
      ...state,
      pull: {
        ...state.pull,
        needsPull: false,
        error: undefined,
      },
    };
  };
}

export function pullFailed(error: string): FileAction {
  return function (state?: FileState): FileState {
    if (!state) {
      throw new Error("File not found");
    }
    return {
      ...state,
      pull: {
        ...state.pull,
        error,
      },
    };
  };
}

export function compose(first: FileAction, ...rest: FileAction[]): FileAction {
  return function (state?: FileState) {
    let result = first(state);
    for (const action of rest) {
      result = action(result);
    }
    return result;
  };
}

export function fakeFileData(value: string): FileData {
  return {
    metadata: {
      name: value,
      isDeleted: false,
      contentType: "text/plain",
      createdAt: 0,
      modifiedAt: 0,
      md5Checksum: "",
    },
    content: new TextEncoder().encode(value),
  };
}

export function encodeString(value: string) {
  return new TextEncoder().encode(value);
}

export function decodeString(buffer: ArrayBuffer) {
  return new TextDecoder().decode(buffer);
}

export function md5(content: ArrayBuffer): string {
  const hash = SparkMD5.ArrayBuffer.hash(content);
  return hash;
}
