import { CheckCircle, Circle } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { CellImage } from "../../CellImage";
import {
  EditorState,
  EditorStateProps,
  useEditorState,
} from "../../notes-hooks";
import { EditorLayout, useEditorKeyboardNav } from "./Editor";

export type GridEditorProps<T> = EditorStateProps<T> & {
  getPrimaryText: (object: T) => string;
  navigate: (object: T) => void;
  placeholder?: string;
  getImageId: (object: T) => string | undefined;
  padding?: number;
};

export function GridEditor<T>(props: GridEditorProps<T>) {
  const state = useEditorState(props);
  const contentProps = { ...props, state };
  const { isEditing, toggleObject } = state;
  const { navigate } = props;
  const onSelect = useCallback(
    (object: T) => {
      if (isEditing) {
        toggleObject(object);
      } else {
        navigate(object);
      }
    },
    [isEditing, navigate, toggleObject],
  );
  useEditorKeyboardNav({ ...state, onSelect });
  return (
    <EditorLayout inputPlaceholder={props.placeholder} state={state}>
      <GridContent {...contentProps} />
    </EditorLayout>
  );
}

type GridContentProps<T> = GridEditorProps<T> & {
  state: EditorState<T>;
};

type CellData<T> = GridContentProps<T> & {
  numCols: number;
  width: number;
  height: number;
};

function GridContent<T>(props: GridContentProps<T>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const numCols = isMobile ? 3 : 5;
  const items = props.state.items;
  const itemCount = items.length;
  const loadMoreItems = () => props.state.loadNextPage();
  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <InfiniteLoader
          isItemLoaded={(i) => i < items.length - 1}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => {
            const columnWidth = width / numCols;
            const rowHeight = columnWidth;
            return (
              <FixedSizeGrid<CellData<T>>
                ref={ref}
                onItemsRendered={({
                  visibleColumnStartIndex,
                  visibleColumnStopIndex,
                  visibleRowStartIndex,
                  visibleRowStopIndex,
                  overscanColumnStartIndex,
                  overscanColumnStopIndex,
                  overscanRowStartIndex,
                  overscanRowStopIndex,
                }) => {
                  const visibleStartIndex =
                    visibleRowStartIndex * numCols + visibleColumnStartIndex;
                  const visibleStopIndex =
                    visibleRowStopIndex * numCols + visibleColumnStopIndex;
                  const overscanStartIndex =
                    overscanRowStartIndex * numCols + overscanColumnStartIndex;
                  const overscanStopIndex =
                    overscanRowStopIndex * numCols + overscanColumnStopIndex;
                  return onItemsRendered({
                    visibleStartIndex,
                    visibleStopIndex,
                    overscanStartIndex,
                    overscanStopIndex,
                  });
                }}
                columnCount={numCols}
                columnWidth={width / numCols}
                height={height}
                width={width}
                rowCount={Math.ceil(props.state.items.length / numCols)}
                rowHeight={width / numCols}
                itemData={{
                  ...props,
                  numCols,
                  width: columnWidth,
                  height: rowHeight,
                }}
              >
                {Cell}
              </FixedSizeGrid>
            );
          }}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

const defaultPadding = 4;
function Cell<T>({
  columnIndex,
  rowIndex,
  style,
  data,
}: {
  columnIndex: number;
  rowIndex: number;
  style: React.CSSProperties;
  data: CellData<T> | undefined;
}) {
  if (!data) {
    return null;
  }
  const {
    numCols,
    state,
    width,
    height,
    getImageId,
    getPrimaryText,
    navigate,
  } = data;
  const { items, isEditing, toggleObject } = state;
  const index = numCols * rowIndex + columnIndex;
  const padding = data.padding ?? defaultPadding;
  const item = items[index];
  if (!item) {
    return null;
  }
  const imageId = getImageId(item.object);
  if (!imageId) {
    return null;
  }
  const primaryText = getPrimaryText(item.object);
  const handleClick = () =>
    isEditing ? toggleObject(item.object) : navigate(item.object);
  return (
    <Box style={{ ...style, padding }}>
      <CellCard
        width={width}
        height={height}
        padding={padding}
        imageId={imageId}
        isEditing={isEditing}
        isSelected={item.selected}
        primaryText={primaryText}
        onClick={handleClick}
      />
    </Box>
  );
}

interface CellCardProps {
  width: number;
  height: number;
  imageId: string;
  padding: number;
  isEditing: boolean;
  isSelected: boolean;
  primaryText: string;
  onClick: () => void;
}
function CellCard(props: CellCardProps) {
  const {
    width,
    height,
    imageId,
    padding,
    isEditing,
    isSelected,
    primaryText,
    onClick,
  } = props;
  const theme = useTheme();
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CellImage
          thumbnailSize={width - 2 * padding}
          id={imageId}
          width={width - 2 * padding}
          height={height - 2 * padding}
        />
        {isEditing && isSelected && (
          <>
            <Box
              style={{
                position: "absolute",
                width: width - 2 * padding,
                top: 0,
                padding: padding,
              }}
            >
              <Circle sx={{ color: "white" }} />
            </Box>
            <Box
              style={{
                position: "absolute",
                width: width - 2 * padding,
                top: 0,
                padding: padding,
              }}
            >
              <CheckCircle color="success" />
            </Box>
          </>
        )}
        <Box
          style={{
            position: "absolute",
            width: width - 2 * padding,
            backgroundColor: theme.palette.background.paper,
            bottom: 0,
            padding: padding,
          }}
        >
          <Typography
            variant="body2"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {primaryText}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}
