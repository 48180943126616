import { SqlValue } from "sql.js";
import { DefaultMap } from "./default-map";
import {
  ColDef,
  ColType,
  ColumnData,
  RowData,
  Schema,
  schemaFrom,
  TableData,
  VirtualDb,
  WritableDb,
} from "./core";
import { LwwMap } from "./lww-map";

export interface RegistersData {
  tableExists: TableExistsData;
  columnExists: ColumnExistsData;
  rowExists: RowExistsData;
  columnType: ColumnTypeData;
  value: ValueRegistersData;
}

export class MemoryVdb implements VirtualDb {
  constructor(private regs: VdbRegisters) {}
  getMaxTimestamp() {
    return this.regs.getMaxTimestamp();
  }
  getTables(): string[] {
    return this.regs.getTables();
  }
  getSchema(table: string): Schema {
    return this.regs.getSchema(table);
  }
  getTableData(table: string, rowIds?: string[] | undefined): TableData {
    return this.regs.getTableData(table, rowIds);
  }
  getColumnData(table: string, column: string): TableData {
    return this.regs.getColumnData(table, column);
  }
  getRowIds(table: string): string[] {
    return this.regs.getRowIds(table);
  }
  updateAt(timestamp: number): WritableDb {
    return new WritableMemoryVdb(timestamp, this.regs);
  }
}

export class WritableMemoryVdb implements WritableDb {
  constructor(
    private timestamp: number,
    private regs: VdbRegisters,
  ) {}
  getTables(): string[] {
    return this.regs.getTables();
  }
  getSchema(table: string): Schema {
    return this.regs.getSchema(table);
  }
  getTableData(table: string, rowIds?: string[] | undefined): TableData {
    return this.regs.getTableData(table, rowIds);
  }
  getColumnData(table: string, column: string): TableData {
    return this.regs.getColumnData(table, column);
  }
  getRowIds(table: string): string[] {
    return this.regs.getRowIds(table);
  }
  createTable(table: string, schema: Schema): boolean {
    const { timestamp, regs } = this;
    const { tableExists, columnExists, columnType } = regs;
    let changed = tableExists.set(table, true, timestamp);
    for (const column of schema.getColumns()) {
      const type = schema.getColumnDef(column).type;
      changed = columnExists.set(table, column, true, timestamp) || changed;
      changed = columnType.set(table, column, type, timestamp) || changed;
    }
    return changed;
  }
  dropTable(table: string): boolean {
    const { timestamp, regs } = this;
    const { tableExists } = regs;
    return tableExists.set(table, false, timestamp);
  }
  addColumn(table: string, column: ColDef): boolean {
    const { timestamp, regs } = this;
    const { columnExists, columnType } = regs;
    let changed = columnExists.set(table, column.name, true, timestamp);
    changed =
      columnType.set(table, column.name, column.type, timestamp) || changed;
    return changed;
  }
  dropColumn(table: string, column: string): boolean {
    const { timestamp, regs } = this;
    const { columnExists } = regs;
    return columnExists.set(table, column, false, timestamp);
  }
  upsertTableData(table: string, data: TableData): boolean {
    const { timestamp, regs } = this;
    const { rowExists, value } = regs;
    let changed = false;
    for (const [rowId, rowData] of Object.entries(data)) {
      changed = rowExists.set(table, rowId, true, timestamp) || changed;
      for (const [column, columnValue] of Object.entries(rowData)) {
        changed =
          value.set(table, column, rowId, columnValue, timestamp) || changed;
      }
    }
    return changed;
  }
  updateColumnData(table: string, column: string, data: ColumnData): boolean {
    const { timestamp, regs } = this;
    const { value } = regs;
    let changed = false;
    for (const [rowId, columnValue] of Object.keys(data)) {
      changed =
        value.set(table, column, rowId, columnValue, timestamp) || changed;
    }
    return changed;
  }
  deleteRows(table: string, rowIds: string[]): boolean {
    const { timestamp, regs } = this;
    const { rowExists } = regs;
    let changed = false;
    for (const rowId of rowIds) {
      changed = rowExists.set(table, rowId, false, timestamp) || changed;
    }
    return changed;
  }
  updateRow(table: string, rowId: string, setList: RowData): boolean {
    const { timestamp, regs } = this;
    const { value } = regs;
    let changed = false;
    for (const [column, sqlValue] of Object.entries(setList)) {
      changed = value.set(table, column, rowId, sqlValue, timestamp) || changed;
    }
    return changed;
  }
}

export class VdbRegisters {
  readonly tableExists: TableExistsRegisters;
  readonly columnExists: ColumnExistsRegisters;
  readonly rowExists: RowExistsRegisters;
  readonly columnType: ColumnTypeRegisters;
  readonly value: ValueRegisters;
  constructor(data?: RegistersData) {
    this.tableExists = new TableExistsRegisters(data?.tableExists);
    this.columnExists = new ColumnExistsRegisters(data?.columnExists);
    this.rowExists = new RowExistsRegisters(data?.rowExists);
    this.columnType = new ColumnTypeRegisters(data?.columnType);
    this.value = new ValueRegisters(data?.value);
  }
  getMaxTimestamp(): number {
    return Math.max(
      this.tableExists.getMaxTimestamp(),
      this.columnExists.getMaxTimestamp(),
      this.rowExists.getMaxTimestamp(),
      this.columnType.getMaxTimestamp(),
      this.value.getMaxTimestamp(),
    );
  }
  getTables(): string[] {
    return this.tableExists.listExistingTables();
  }
  getSchema(table: string): Schema {
    const { columnExists, columnType } = this;
    const columns = columnExists.listExistingColumns(table);
    const defs = columns.map((name) => ({
      name,
      type: columnType.get(table, name),
    }));
    return schemaFrom(defs);
  }
  getTableData(table: string, rowIds?: string[] | undefined): TableData {
    const ids = rowIds ?? this.rowExists.listExistingRows(table);
    const cols = this.columnExists.listExistingColumns(table);
    const data: TableData = {};
    const schema = this.getSchema(table);
    for (const rowId of ids) {
      data[rowId] = {};
      for (const col of cols) {
        const def = schema.getColumnDef(col);
        const value = this.value.get(table, col, rowId);
        const casted = nativeToSql(value, def.type);
        data[rowId][col] = casted;
      }
    }
    return data;
  }
  getColumnData(table: string, column: string): TableData {
    const data: TableData = {};
    for (const row of this.rowExists.listExistingRows(table)) {
      data[row] = { [column]: this.value.get(table, column, row) };
    }
    return data;
  }
  getRowIds(table: string): string[] {
    return this.rowExists.listExistingRows(table);
  }
  dump(): RegistersData {
    return {
      tableExists: this.tableExists.dump(),
      columnExists: this.columnExists.dump(),
      rowExists: this.rowExists.dump(),
      columnType: this.columnType.dump(),
      value: this.value.dump(),
    };
  }
}

const defaultFalse = () => false;
export type TableExistsData = Record<string, [boolean, number]>;
export class TableExistsRegisters {
  private regs: LwwMap<boolean>;
  constructor(data?: TableExistsData) {
    this.regs = new LwwMap(defaultFalse, data);
  }
  listExistingTables() {
    return this.regs
      .entries()
      .filter(([_table, exists]) => exists)
      .map(([table]) => table);
  }
  set(table: string, value: boolean, timestamp: number): boolean {
    return this.regs.trySet(table, value, timestamp);
  }
  get(table: string): boolean {
    return this.regs.get(table);
  }
  getMaxTimestamp() {
    return this.regs.getMaxTimestamp();
  }
  dump(): TableExistsData {
    return this.regs.dump();
  }
}

export type ColumnExistsData = Record<
  string,
  Record<string, [boolean, number]>
>;
export class ColumnExistsRegisters {
  private regs: DefaultMap<LwwMap<boolean>>;
  constructor(data?: ColumnExistsData) {
    this.regs = new DefaultMap(() => new LwwMap(defaultFalse));
    if (data) {
      for (const table of Object.keys(data)) {
        for (const [column, [exists, timestamp]] of Object.entries(
          data[table],
        )) {
          this.regs.get(table).trySet(column, exists, timestamp);
        }
      }
    }
  }
  listExistingColumns(table: string) {
    return this.regs
      .get(table)
      .entries()
      .filter(([_column, exists]) => exists)
      .map(([column]) => column);
  }
  set(table: string, column: string, value: boolean, timestamp: number) {
    return this.regs.get(table).trySet(column, value, timestamp);
  }
  get(table: string, column: string) {
    return this.regs.get(table).get(column);
  }
  getMaxTimestamp() {
    const timestamps = this.regs.values().map((x) => x.getMaxTimestamp());
    return Math.max(...timestamps);
  }
  dump(): ColumnExistsData {
    const results: ColumnExistsData = {};
    for (const table of this.regs.keys()) {
      results[table] = this.regs.get(table).dump();
    }
    return results;
  }
}

export type RowExistsData = Record<string, Record<string, [boolean, number]>>;
export class RowExistsRegisters {
  private regs: DefaultMap<LwwMap<boolean>>;
  constructor(data?: RowExistsData) {
    this.regs = new DefaultMap(() => new LwwMap(defaultFalse));
    if (data) {
      for (const [table, rowData] of Object.entries(data)) {
        this.regs.set(table, new LwwMap(defaultFalse, rowData));
      }
    }
  }
  listExistingRows(table: string) {
    return this.regs
      .get(table)
      .entries()
      .filter(([_row, exists]) => exists)
      .map(([row]) => row);
  }
  set(table: string, row: string, value: boolean, timestamp: number) {
    return this.regs.get(table).trySet(row, value, timestamp);
  }
  get(table: string, row: string) {
    return this.regs.get(table).get(row);
  }
  getMaxTimestamp() {
    const timestamps = this.regs.values().map((x) => x.getMaxTimestamp());
    return Math.max(...timestamps);
  }
  dump(): RowExistsData {
    const results: RowExistsData = {};
    for (const table of this.regs.keys()) {
      results[table] = this.regs.get(table).dump();
    }
    return results;
  }
}

export type ColumnTypeData = Record<string, Record<string, [ColType, number]>>;
const defaultColType = (): ColType => "blob";
export class ColumnTypeRegisters {
  private regs = new DefaultMap(() => new LwwMap(defaultColType));
  constructor(data?: ColumnTypeData) {
    if (data) {
      for (const table of Object.keys(data)) {
        for (const [column, [type, timestamp]] of Object.entries(data[table])) {
          this.regs.get(table).trySet(column, type, timestamp);
        }
      }
    }
  }
  set(table: string, column: string, value: ColType, timestamp: number) {
    const result = this.regs.get(table).trySet(column, value, timestamp);
    return result;
  }
  get(table: string, column: string) {
    return this.regs.get(table).get(column);
  }
  getMaxTimestamp() {
    const timestamps = this.regs.values().map((x) => x.getMaxTimestamp());
    return Math.max(...timestamps);
  }
  dump(): ColumnTypeData {
    const results: ColumnTypeData = {};
    for (const table of this.regs.keys()) {
      results[table] = this.regs.get(table).dump();
    }
    return results;
  }
}

const defaultSqlValue = (): SqlValue => null;
export type ValueRegistersData = Record<
  string,
  Record<string, Record<string, [SqlValue, number]>>
>;
export class ValueRegisters {
  private regs = new DefaultMap(
    () => new DefaultMap(() => new LwwMap(defaultSqlValue)),
  );
  constructor(data?: ValueRegistersData) {
    if (!data) {
      return;
    }
    for (const table of Object.keys(data)) {
      for (const column of Object.keys(data[table])) {
        const map = new LwwMap(defaultSqlValue, data[table][column]);
        this.regs.get(table).set(column, map);
      }
    }
  }
  set(
    table: string,
    column: string,
    row: string,
    value: SqlValue,
    timestamp: number,
  ) {
    return this.regs.get(table).get(column).trySet(row, value, timestamp);
  }
  get(table: string, column: string, row: string) {
    return this.regs.get(table).get(column).get(row);
  }
  getMaxTimestamp() {
    const timestamps = this.regs
      .values()
      .flatMap((x) => x.values())
      .map((x) => x.getMaxTimestamp());
    return Math.max(...timestamps);
  }
  dump(): ValueRegistersData {
    const results: ValueRegistersData = {};
    for (const table of this.regs.keys()) {
      results[table] = {};
      for (const column of this.regs.get(table).keys()) {
        results[table][column] = this.regs.get(table).get(column).dump();
      }
    }
    return results;
  }
}

function nativeToSql(value: SqlValue, type: ColType): SqlValue {
  switch (type) {
    case "blob":
      return value;
    case "integer":
      return value;
    case "null":
      return null;
    case "real":
      return value;
    case "text":
      if (value === null || typeof value === "string") {
        return value;
      } else {
        return value.toLocaleString();
      }
  }
}
