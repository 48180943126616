import { Launch } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { EditorStateProps, Item, useEditorState } from "../../notes-hooks";
import { EditorLayout, useEditorKeyboardNav } from "./Editor";

export type ListEditorProps<T> = EditorStateProps<T> & {
  getPrimaryText: (object: T) => string;
  getSecondaryText: (object: T) => string;
  navigate: (object: T) => void;
  placeholder?: string;
  getAddText: (query: string) => React.ReactNode;
};

export function ListEditor<T>(props: ListEditorProps<T>) {
  const state = useEditorState(props);
  const {
    isEditing,
    input,
    items,
    toggleObject,
    addObject,
    selection,
    loadNextPage,
  } = state;
  const { navigate } = props;
  const onSelect = useCallback(
    (object: T) => {
      if (isEditing) {
        toggleObject(object);
      } else {
        navigate(object);
      }
    },
    [toggleObject, navigate, isEditing],
  );
  useEditorKeyboardNav({ ...state, onSelect });
  return (
    <EditorLayout inputPlaceholder={props.placeholder} state={state}>
      <ListContent
        items={items}
        toggle={toggleObject}
        isEditing={isEditing}
        getPrimaryText={props.getPrimaryText}
        getSecondaryText={props.getSecondaryText}
        navigate={props.navigate}
        selectionIndex={selection.index}
        loadNextPage={loadNextPage}
      />
      {isEditing && items.length === 0 && (
        <NoHits text={props.getAddText(input)} onClick={addObject} />
      )}
    </EditorLayout>
  );
}

function NoHits({
  text,
  onClick,
}: {
  text: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <List sx={{ flexGrow: 1 }}>
      <ListItem disablePadding>
        <ListItemButton onClick={onClick}>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    </List>
  );
}

interface RowData<T> {
  items: Item<T>[];
  toggle: (object: T) => void;
  isEditing: boolean;
  getPrimaryText: (object: T) => string;
  getSecondaryText: (object: T) => string;
  navigate: (object: T) => void;
  selectionIndex: number | undefined;
}

function Row<T>({
  index,
  style,
  data,
}: {
  index: number;
  style: React.CSSProperties;
  data: RowData<T> | undefined;
}) {
  if (!data) {
    return null;
  }
  const {
    items,
    toggle,
    isEditing,
    getPrimaryText,
    getSecondaryText,
    navigate,
    selectionIndex,
  } = data;
  const item = items[index];
  const highlighted = selectionIndex === index;
  return (
    <ItemView
      style={style}
      item={item}
      toggle={toggle}
      isEditing={isEditing}
      getPrimaryText={getPrimaryText}
      getSecondaryText={getSecondaryText}
      navigate={navigate}
      highlighted={highlighted}
      keyboardNavigating={selectionIndex !== undefined}
    />
  );
}

function ListContent<T>({
  items,
  toggle,
  isEditing,
  getPrimaryText,
  getSecondaryText,
  navigate,
  selectionIndex,
  loadNextPage,
}: {
  items: Item<T>[];
  toggle: (object: T) => void;
  isEditing: boolean;
  getPrimaryText: (object: T) => string;
  getSecondaryText: (object: T) => string;
  navigate: (object: T) => void;
  selectionIndex: number | undefined;
  loadNextPage: () => void;
}) {
  // memo because we need to pack all of this stuff into a single
  // object to get passed into Row
  const itemData = useMemo(
    () => ({
      items,
      toggle,
      isEditing,
      getPrimaryText,
      getSecondaryText,
      navigate,
      selectionIndex,
    }),
    [
      items,
      toggle,
      isEditing,
      getPrimaryText,
      getSecondaryText,
      navigate,
      selectionIndex,
    ],
  );
  const virtualLoaderRef = useRef<InfiniteLoader>(null);
  useEffect(() => {
    // https://github.com/bvaughn/react-window/issues/463
    if (selectionIndex === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      virtualLoaderRef.current?._listRef.scrollTo(0);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      virtualLoaderRef.current?._listRef.scrollToItem(selectionIndex, "smart");
    }
  }, [selectionIndex]);
  const itemCount = items.length;
  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <InfiniteLoader
          ref={virtualLoaderRef}
          isItemLoaded={(i) => i < items.length - 1}
          itemCount={itemCount}
          loadMoreItems={loadNextPage}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList<RowData<T>>
              ref={ref}
              onItemsRendered={onItemsRendered}
              itemData={itemData}
              height={height}
              itemCount={itemData.items.length}
              itemSize={60}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

function ItemView<T>({
  item,
  toggle,
  isEditing,
  style,
  getPrimaryText,
  getSecondaryText,
  navigate,
  highlighted,
  keyboardNavigating,
}: {
  item: Item<T>;
  toggle: (object: T) => void;
  isEditing: boolean;
  style: React.CSSProperties;
  getPrimaryText: (object: T) => string;
  getSecondaryText: (object: T) => string;
  navigate: (object: T) => void;
  highlighted: boolean;
  keyboardNavigating: boolean;
}) {
  const primaryText = getPrimaryText(item.object);
  const secondaryText = getSecondaryText(item.object);
  const sx = keyboardNavigating
    ? { "&:hover": { backgroundColor: "transparent" }, height: "100%" }
    : { height: "100%" };
  return (
    <ListItem
      style={style}
      secondaryAction={
        isEditing ? (
          <IconButton edge="end" onClick={() => navigate(item.object)}>
            <Launch />
          </IconButton>
        ) : null
      }
      disablePadding
    >
      <ListItemButton
        onClick={() =>
          isEditing ? toggle(item.object) : navigate(item.object)
        }
        selected={highlighted}
        sx={sx}
        dense
      >
        {isEditing && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={item.selected}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
        )}
        <ItemContent primaryText={primaryText} secondaryText={secondaryText} />
      </ListItemButton>
    </ListItem>
  );
}

export const ItemContent = memo(function ItemContent({
  primaryText,
  secondaryText,
}: {
  primaryText: string;
  secondaryText: string;
}) {
  return (
    <ListItemText
      primary={primaryText}
      secondary={secondaryText}
      primaryTypographyProps={{
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
      secondaryTypographyProps={{
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
    />
  );
});
