import { AppDb } from "./app-db";
import { AuthManager } from "./auth";
import {
  broadcastChanges,
  DbFileStore,
  ObservableFileStore,
  observe,
  receiveChanges,
} from "./file-store";
import { gdriveIdGenerator } from "./google-drive/google-drive-id-generator";
import { IdGeneratorByType } from "./id-generator";
import { Logger } from "./logging";
import { addSource, deleteSource, SourceType } from "./source-state";
import {
  DbSourceStore,
  defaultSourceId,
  initializeSources,
  SourceStore,
} from "./source-store";
import {
  compose,
  FileData,
  localAdd,
  localUpdate,
  needsPush,
  requestCrawl,
  requestPull,
} from "./file-state";
import { GDriveFolder } from "./google-drive/google-folder-picker";
import { shortId } from "./uuid";

export interface FileSystemConfig {
  readonly files: ObservableFileStore;
  readonly sources: SourceStore;
  readonly idGenerator: IdGeneratorByType;
  readonly logger: Logger;
}
export class AppFileSystem {
  readonly files: ObservableFileStore;
  readonly sources: SourceStore;
  readonly idGenerator: IdGeneratorByType;
  constructor(config: FileSystemConfig) {
    const { files, sources, idGenerator } = config;
    this.files = files;
    this.sources = sources;
    this.idGenerator = idGenerator;
  }
  fileListObservable() {
    return this.files.observable;
  }
  async fileStats() {
    return this.files.stats();
  }
  async setFileData(id: string, data: FileData) {
    return this.files.apply(id, localUpdate(data));
  }
  async getFileData(id: string) {
    const state = await this.files.get(id);
    return state?.data;
  }
  async getSyncState(id: string) {
    const state = await this.files.get(id);
    return {
      needsPush: needsPush(state),
      pushError: state?.push?.error,
      needsPull: state ? state.pull.needsPull : false,
      needsCrawl: state ? state.crawl.needsCrawl : false,
    };
  }
  async listFiles(sourceId: string) {
    const results: string[] = [];
    const ids = await this.files.listSource(sourceId);
    for (const id of ids) {
      const state = await this.files.get(id);
      if (!state || state.data?.metadata.isDeleted) {
        continue;
      }
      results.push(id);
    }
    return results;
  }
  async addFile(sourceId: string, data: FileData) {
    const source = await this.getSource(sourceId);
    const generator = this.idGenerator[source.type];
    const id = await generator();
    const add = localAdd(sourceId, data);
    await this.files.apply(id, add);
    return id;
  }
  async getSource(id: string) {
    const state = await this.sources.get(id);
    if (!state) {
      throw new Error("source not found");
    }
    return state;
  }
  async getParent(sourceId: string) {
    const source = await this.getSource(sourceId);
    if (source.type === SourceType.gdrive) {
      const folder = source.config as GDriveFolder;
      return folder.id;
    }
    return undefined;
  }
  async addSource(name: string, type: SourceType, config: unknown) {
    const sourceId = shortId();
    const add = addSource(name, type, config);
    await this.sources.apply(sourceId, add);
    if (type === SourceType.gdrive) {
      const folder = config as GDriveFolder;
      const cmd = compose(requestPull(sourceId), requestCrawl(sourceId));
      await this.files.apply(folder.id, cmd);
    }
    return sourceId;
  }
  async deleteSource(sourceId: string) {
    this.sources.apply(sourceId, deleteSource());
  }
  async listSources(includeDefault = true) {
    const ids = await this.sources.list();
    const results: string[] = [];
    for (const id of ids) {
      if (id === defaultSourceId && !includeDefault) {
        continue;
      }
      const source = await this.sources.get(id);
      if (source && !source.isDeleted) {
        results.push(id);
      }
    }
    return results;
  }
  async getSourceByName(name: string) {
    const ids = await this.sources.getByName(name);
    if (ids.length > 1) {
      return { error: "multiple sources with same name" };
    } else if (ids.length === 0) {
      return { error: "source not found" };
    }
    const id = ids[0];
    const source = await this.sources.get(id);
    if (!source) {
      return { error: "source not found" };
    }
    return { id, source };
  }
}

function initializeFileStore(db: AppDb, channelName: string) {
  const fileStore = observe(new DbFileStore(db));
  const channel = new BroadcastChannel(channelName);
  broadcastChanges(fileStore, channel);
  return receiveChanges(fileStore, channel);
}

async function initializeIdGenerator(db: AppDb, auth: AuthManager) {
  const googleAuth = auth.getGoogle();
  const idGenerator = {
    [SourceType.gdrive]: gdriveIdGenerator(db, async () => {
      const auth = await googleAuth();
      return auth.token;
    }),
    [SourceType.default]: async () => shortId(),
  };
  return idGenerator;
}

let singleton: AppFileSystem;

export async function initializeFs(
  db: AppDb,
  auth: AuthManager,
  channelName: string,
  logger: Logger,
) {
  const files = initializeFileStore(db, channelName);
  const sources = new DbSourceStore(db);
  const idGenerator = await initializeIdGenerator(db, auth);
  await initializeSources(sources);
  singleton = new AppFileSystem({
    files,
    sources,
    logger,
    idGenerator,
  });
  return singleton;
}

export function getFs() {
  if (!singleton) {
    throw new Error("fs not initialized");
  }
  return singleton;
}
