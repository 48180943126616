import { AppDb } from "../../data/app-db";
import { AppFileSystem } from "../../data/app-file-system";
import { AppSqlApi, DbManager } from "../../data/app-sql-api";
import { Change } from "../../data/conflict-free-db/core";
import { isSqlFile } from "../../data/db-persistence";
import { FileData, md5 } from "../../data/file-state";
import { FileChange } from "../../data/file-store";
import { defaultSourceId } from "../../data/source-store";
import { shortId } from "../../data/uuid";
import { AttachmentListItem, DbAttachments } from "./db-attachments";
import { DbChatMessages } from "./db-chat-messages";
import { DbLinks, LinkListItem, extractUrlsFromObject } from "./db-links";
import { DbObject, DbObjects } from "./db-objects";
import { DbProps } from "./db-properties";
import { DbQueries } from "./db-queries";
import { DbSearchTerms } from "./db-search-terms";
import { DbTags } from "./db-tags";
import { Migration, PrivateTableMigration } from "./migration";

export interface ObjectListItem {
  readonly id: string;
  readonly title: string;
  readonly text: string;
}

export interface FolderPlaceImage {
  readonly imageId: string;
}

export interface YouTubeImage {
  readonly youTubeId: string;
}

export type MainImage =
  | { type: "folderPlace"; image: FolderPlaceImage }
  | { type: "youTube"; image: YouTubeImage };

export type JsonValue =
  | string
  | number
  | boolean
  | null
  | JsonObject
  | JsonArray;
interface JsonObject {
  [key: string]: JsonValue;
}
interface JsonArray extends Array<JsonValue> {}

export interface ObjectDetails {
  readonly id: string;
  readonly title: string;
  readonly text?: string;
  readonly attachments: ReadonlyArray<AttachmentListItem>;
  readonly links: ReadonlyArray<LinkListItem>;
  readonly mainImage?: MainImage;
  readonly visibility: "visible" | "internal" | "trashed";
  readonly isStarred: boolean;
  readonly type: "file" | "note" | "unknown";
  readonly createdAt: Date;
  readonly modifiedAt: Date;
  readonly views: ReadonlyArray<ViewRef>;
  readonly defaultViewId: string;
  readonly properties: Record<string, JsonValue>;
}

export type ViewRef = BuiltinViewRef;

export interface BuiltinViewRef {
  readonly type: "builtin";
  readonly title: string;
  readonly builtinType:
    | "content"
    | "tags"
    | "related"
    | "gallery"
    | "explore"
    | "network"
    | "sql"
    | "canvas"
    | "imageList"
    | "agent"
    | "settings";
  readonly id: string;
}
export function builtinView(
  type: BuiltinViewRef["builtinType"],
  title: string
): ViewRef {
  return { type: "builtin", builtinType: type, title, id: type };
}

const defaultBulitinId = "content";

export interface FpFileSystem {
  getFileData(id: string): Promise<FileData | undefined>;
  getParent(id: string): Promise<string | undefined>;
  setFileData(id: string, data: FileData): Promise<FileChange>;
  addFile(sourceId: string, data: FileData): Promise<string>;
}

export async function initializeFpApis(
  appDb: AppDb,
  fs: AppFileSystem,
  dbs: DbManager
) {
  const manager = new FpApiManager(appDb);
  for (const sourceId of await fs.sources.list()) {
    if (sourceId === defaultSourceId) {
      continue;
    }
    const db = dbs.getDb(sourceId);
    await manager.create(sourceId, db, fs);
  }
  return manager;
}

export class PrivateDbChangeHandler {
  constructor(
    private migration: Migration,
    private dbObjects: DbObjects,
    private dbLinks: DbLinks
  ) {}
  handleSharedChange(change: Change) {
    for (const { row, table } of change.operation.affectedRows()) {
      if (table !== this.dbObjects.tableName) {
        continue;
      }
      this.handleObjectChangeForId(row);
    }
  }
  handleObjectChangeForId(id: string) {
    const obj = this.dbObjects.get(id);
    if (!obj) {
      return;
    }
    this.handleObjectChange(obj);
  }
  handleObjectChange(obj: DbObject) {
    const urls = extractUrlsFromObject(obj);
    this.dbLinks.updateLinks(obj.id, urls);
  }
  async initialize() {
    await this.dbLinks.initialize(this.migration);
    if (this.migration.needsBackfill()) {
      const objs = this.dbObjects.listObjects();
      for (const obj of objs) {
        this.handleObjectChange(obj);
      }
    }
  }
}

export class FpApiManager {
  private apis: Record<string, FpApi> = {};
  private cleanup: Record<string, () => void> = {};
  constructor(private appDb: AppDb) {}
  async create(sourceId: string, db: AppSqlApi, fs: AppFileSystem) {
    const dbObjects = new DbObjects(db);
    const dbTags = new DbTags(db, dbObjects);
    const dbAttachments = new DbAttachments(db);
    const dbLinks = new DbLinks(db);
    const dbSearchTerms = new DbSearchTerms(db);
    const dbProps = new DbProps(db);
    const dbChatMessages = new DbChatMessages(db);
    const api = new FpApi(
      db,
      sourceId,
      fs,
      dbObjects,
      dbTags,
      dbAttachments,
      dbLinks,
      dbSearchTerms,
      dbProps,
      dbChatMessages
    );
    api.initialize();
    this.apis[sourceId] = api;
    const migration = new PrivateTableMigration(this.appDb);
    const handler = new PrivateDbChangeHandler(migration, dbObjects, dbLinks);
    await handler.initialize();
    const objSub = db
      .allChangeEvents()
      .subscribe((change) => handler.handleSharedChange(change));
    const fileSub = fs.files.observable.subscribe(async ({ id }) => {
      const state = await fs.files.get(id);
      if (!state || isSqlFile(state)) {
        return;
      }
      api.handleFileChanged(id);
    });
    const root = await fs.getParent(sourceId);
    if (root) {
      api.setObjectVisibility(root, "internal");
    }
    this.cleanup[sourceId] = () => {
      fileSub.unsubscribe();
      objSub.unsubscribe();
    };
    return api;
  }
  get(sourceId: string) {
    const api = this.apis[sourceId];
    if (!api) {
      throw new Error(`Source not found: ${sourceId}`);
    }
    return api;
  }
  delete(sourceId: string) {
    delete this.apis[sourceId];
    this.cleanup[sourceId]();
  }
}

export class FpApi {
  private queries: DbQueries;
  constructor(
    db: AppSqlApi,
    private sourceId: string,
    private fileSystem: FpFileSystem,
    readonly dbObjects: DbObjects,
    readonly dbTags: DbTags,
    readonly dbAttachments: DbAttachments,
    readonly dbLinks: DbLinks,
    readonly dbSearchTerms: DbSearchTerms,
    readonly dbProps: DbProps,
    readonly dbChatMessages: DbChatMessages
  ) {
    this.queries = new DbQueries(db);
  }
  initialize() {
    this.dbObjects.initialize();
    this.dbAttachments.initialize();
    this.dbTags.initialize();
    this.dbSearchTerms.initialize();
    this.dbProps.initialize();
    this.dbChatMessages.initialize();
  }
  relatedYouTube(id: string) {
    return this.queries.relatedYouTube(id);
  }
  getObjectsWithIds(ids: string[], includeTrashed = false): ObjectListItem[] {
    return this.queries.getObjectsWithIds(ids, includeTrashed);
  }
  getSqlQueries() {
    const root = this.getSqlQueriesRootObj();
    const objs = this.queries.objectsWithTag(root.id, "");
    return objs;
  }
  updateObjectProperties(id: string, props: Record<string, JsonValue>) {
    for (const [name, value] of Object.entries(props)) {
      this.dbProps.set(id, name, JSON.stringify(value));
    }
  }
  objectDetails(id: string): ObjectDetails | undefined {
    const dbObj = this.dbObjects.get(id);
    if (!dbObj) {
      return;
    }
    const attachments = this.dbAttachments.attachmentsForObject(id);
    const urls = this.queries.getObjectLinks(id);
    const rawProps = this.dbProps.get(id);
    const props: Record<string, JsonValue> = {};
    for (const rawProp of rawProps) {
      if (rawProp.name && rawProp.value) {
        try {
          props[rawProp.name] = JSON.parse(rawProp.value);
        } catch (e) {
          // Ignore
        }
      }
    }
    return {
      id,
      title: dbObj.title || "Untitled",
      text: dbObj.text,
      attachments,
      mainImage: this.getMainImage(id),
      type: dbObj.objectType ?? "unknown",
      visibility: dbObj.visibility ?? "visible",
      createdAt: new Date(dbObj.createdAt ?? 0),
      modifiedAt: new Date(dbObj.modifiedAt ?? 0),
      links: urls,
      isStarred: !!dbObj.isStarred,
      views: this.getBuiltinViewRefs(id),
      defaultViewId: dbObj.defaultViewId ?? defaultBulitinId,
      properties: props,
    };
  }
  private getMainImage(id: string): MainImage | undefined {
    const imageId = this.queries.getMainImageId(id);
    if (imageId) {
      return { type: "folderPlace", image: { imageId } };
    }
    const links = this.queries.getObjectLinks(id);
    const youtTubeId = links.map((x) => x.youTubeId)[0];
    if (youtTubeId) {
      return { type: "youTube", image: { youTubeId: youtTubeId } };
    }
  }
  addSearchTerm(objectId: string, term: string) {
    this.dbSearchTerms.addSearchTerm(objectId, term);
  }
  removeSearchTerm(objectId: string, term: string) {
    this.dbSearchTerms.removeSearchTerm(objectId, term);
  }
  getSearchTermsForObjectId(objectId: string) {
    return this.dbSearchTerms.getSearchTermsForObjectId(objectId);
  }
  addNoteWithId(id: string, title: string, text: string): void {
    this.addObject({
      id,
      title,
      text,
      objectType: "note",
    });
  }
  addNote(title: string, text: string): string {
    const id = shortId();
    this.addObject({
      id,
      title,
      text,
      objectType: "note",
    });
    return id;
  }
  setDefaultView(id: string, viewId: string) {
    this.updateObject({ id, defaultViewId: viewId });
  }
  setObjectStarred(id: string, isStarred: boolean) {
    const obj = this.dbObjects.get(id);
    if (!obj) {
      return;
    }
    this.updateObject({ id, isStarred });
  }
  setObjectVisibility(
    id: string,
    visibility: "visible" | "internal" | "trashed"
  ) {
    const obj = this.dbObjects.get(id);
    if (!obj) {
      return;
    }
    this.updateObject({ id, visibility });
  }
  async addFile(file: File): Promise<string> {
    const id = await this.saveFileToFs(file);
    this.updateObject({
      id,
      title: file.name,
      objectType: "file",
      contentType: file.type,
    });
    this.dbAttachments.add({
      id,
      contentType: file.type,
      fileId: id,
      objectId: id,
    });
    return id;
  }
  async attachFile(objectId: string, file: File): Promise<string> {
    const existing = this.dbObjects.get(objectId);
    if (existing?.objectType === "file") {
      throw new Error("Cannot attach files to file-backed objects.");
    }
    const fileId = await this.saveFileToFs(file);
    this.addObject({
      id: fileId,
      title: file.name,
      objectType: "file",
      contentType: file.type,
      visibility: "internal",
    });
    const id = shortId();
    this.dbAttachments.add({
      id,
      fileId,
      objectId,
      contentType: file.type,
    });
    this.dbObjects.touch(objectId);
    return id;
  }
  async handleFileChanged(fileId: string) {
    const data = await this.fileSystem.getFileData(fileId);
    if (!data) {
      return;
    }
    const current = this.dbObjects.get(fileId);
    const contentTypeChanged =
      current?.contentType !== data.metadata.contentType;
    const titleChanged = current?.title !== data.metadata.name;
    if (!current) {
      this.addObject({
        id: fileId,
        contentType: data.metadata.contentType,
        title: data.metadata.name,
        objectType: "file",
        createdAt: data.metadata.createdAt,
      });
      this.dbAttachments.add({
        id: fileId,
        contentType: data.metadata.contentType,
        fileId,
        objectId: fileId,
        createdAt: data.metadata.createdAt,
      });
    } else if (contentTypeChanged || titleChanged) {
      this.updateObject({
        id: fileId,
        contentType: data.metadata.contentType,
        title: data.metadata.name,
        objectType: "file",
      });
      this.dbAttachments.add({
        id: fileId,
        contentType: data.metadata.contentType,
        fileId,
        objectId: fileId,
      });
    }
  }
  async setTitle(objectId: string, title: string): Promise<void> {
    const obj = this.dbObjects.get(objectId);
    if (!obj) {
      throw new Error(`Object not found: ${objectId}`);
    }
    if (obj.objectType === "file") {
      const data = await this.fileSystem.getFileData(objectId);
      if (!data) {
        throw new Error(`File not found: ${objectId}`);
      }
      await this.fileSystem.setFileData(objectId, {
        ...data,
        metadata: {
          ...data.metadata,
          name: title,
        },
      });
      return;
    }
    this.updateObject({ id: objectId, title });
  }
  setText(objectId: string, text: string): void {
    this.updateObject({ id: objectId, text });
  }
  private tagId(objectId: string, tagObjectId: string) {
    return `${objectId}.${tagObjectId}`;
  }
  addTag(objectId: string, tagObjectId: string) {
    const id = this.tagId(objectId, tagObjectId);
    this.dbTags.add({ id, objectId, tagObjectId });
    this.dbObjects.touch(objectId);
    this.dbObjects.touch(tagObjectId);
    return id;
  }
  hasTag(objectId: string, tagObjectId: string) {
    const id = this.tagId(objectId, tagObjectId);
    return !!this.dbTags.get(id);
  }
  deleteTag(tagId: string) {
    const dbTag = this.dbTags.get(tagId);
    this.dbTags.delete(tagId);
    if (dbTag?.objectId) {
      this.dbObjects.touch(dbTag.objectId);
    }
    if (dbTag?.tagObjectId) {
      this.dbObjects.touch(dbTag.tagObjectId);
    }
  }
  deleteTagWhere(objectId: string, tagObjectId: string) {
    this.dbTags.deleteWhere(objectId, tagObjectId);
  }
  deleteAttachment(id: string) {
    const attachment = this.dbAttachments.get(id);
    this.dbAttachments.delete(id);
    if (attachment?.objectId) {
      this.dbObjects.touch(attachment.objectId);
    }
  }
  getOrCreateObjectByTitle(title: string): ObjectDetails {
    const items = this.queries.objectsByTitle(title);
    const item = items[0];
    const existing = this.objectDetails(item?.id ?? "");
    if (existing) {
      return existing;
    }
    const id = shortId();
    this.addObject({ id, title, objectType: "note" });
    const result = this.objectDetails(id);
    if (!result) {
      throw new Error("Failed to create object");
    }
    return result;
  }
  private async saveFileToFs(file: File): Promise<string> {
    const sourceId = this.sourceId;
    const content = await file.arrayBuffer();
    const md5Checksum = md5(content);
    const parent = await this.fileSystem.getParent(sourceId);
    const data: FileData = {
      content,
      metadata: {
        isDeleted: false,
        name: file.name,
        parent,
        contentType: file.type,
        createdAt: new Date().getTime(),
        modifiedAt: new Date().getTime(),
        md5Checksum,
      },
    };
    return this.fileSystem.addFile(sourceId, data);
  }
  private updateObject(obj: DbObject) {
    this.dbObjects.update(obj);
  }
  private addObject(obj: DbObject) {
    this.dbObjects.add(obj);
  }

  private getBuiltinViewRefs(id: string): ReadonlyArray<ViewRef> {
    const tagCount = this.queries.tagCount(id);
    const relatedCount = this.queries.relatedCount(id);
    const galleryCount = this.queries.galleryCount(id);
    return [
      builtinView("content", "Content"),
      builtinView("tags", `Tags (${tagCount})`),
      builtinView("related", `Related (${relatedCount})`),
      builtinView("gallery", `Gallery (${galleryCount})`),
      builtinView("explore", "Explore"),
      builtinView("network", "Network"),
      builtinView("sql", "SQL"),
      builtinView("canvas", "Canvas"),
      builtinView("settings", "Settings"),
      builtinView("imageList", "Images"),
      builtinView("agent", "Agent"),
    ];
  }

  private getSqlQueriesRootObj(): DbObject {
    const id = "_folderPlaceSqlQueries";
    const existing = this.dbObjects.get(id);
    if (!existing) {
      const obj: DbObject = {
        id,
        title: "SQL Queries",
        objectType: "note",
        visibility: "visible",
      };
      this.addObject(obj);
      return obj;
    }
    if (existing.visibility !== "visible") {
      this.updateObject({ ...existing, visibility: "visible" });
    }
    return existing;
  }
}

export interface GroupItem {
  id: string;
  title: string;
  total: number;
}

export interface GroupMember {
  id: string;
  title: string;
  text: string;
  groupId: string;
}
