import { Delete, Restore } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { FpApi, ObjectDetails } from "../../fp-api";

export function HideControl({
  object,
  api,
}: {
  object: ObjectDetails;
  api: FpApi;
}) {
  const { visibility } = object;
  const icon = visibility === "trashed" ? <Restore /> : <Delete />;
  const handleClick = () =>
    api.setObjectVisibility(
      object.id,
      visibility === "trashed" ? "visible" : "trashed",
    );
  return (
    <Tooltip
      title={visibility === "trashed" ? "Click to restore" : "Click to delete"}
    >
      <IconButton onClick={handleClick}>{icon}</IconButton>
    </Tooltip>
  );
}
